@import "../styles/Colours.scss";
@import "../../themes/themes.scss";

.loading-message {
	padding: 20px 0 25px 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	h4 {
		font-size: 18px;
		@include themify($themes) {
			color: themed('secondary-colour');
		}
	}

	.loading-spinner {
		margin-bottom: 5px;
		
		svg {
			width: 35px;
			height: 35px;

			path {
				@include themify($themes) {
					fill: themed('primary-colour');
				}
			}
		}
	}
}