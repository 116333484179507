@import "../../themes/themes.scss";

.popup-menu-item-wrapper {
	display: flex;
	align-items: center;
	padding: 0.75rem 0.5rem;
	width: 100%;
	position: relative;
	cursor: pointer;

	&:hover {
		color: #208BA5;
		background-color: #E2F1F4;
	}
}

.popup-menu-item-icon-wrapper {
	margin-right: 8px;
	display: flex;
	align-items: center;
	
	svg {
		@include themify($themes) {
			color: themed('darkest-colour');
		}
		width: 1rem;
		height: 1rem;
	}
}

.popup-menu-item-disabled {
	opacity: 0.5;
	pointer-events: none;
}