@import "../../../../core-components/styles/Colours.scss";
@import "../../../../themes/themes.scss";
@import "../../../../core-components/styles/Variables.scss";
@import "../../../../core-components/styles/Mixins.scss";

#my-documents-wrapper {
	flex: 1 1;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	
	.portal-content {
		height: 100%
	}
}

#portal-my-documents {
	flex: 1;
	width: 100%;

	table {
		width: 100%;
		td,
		th {
			box-sizing: border-box;
		}
	}

	.th-name,
	.td-download {
		cursor: pointer;
	}

	.td-download,
	.th-download {
        max-width: 60px;
        text-align: right;
	}

	.td-name,
	.td-size {
		text-align: left;
	}

	th {
		text-transform: uppercase;
		font-size: 12px;
		font-weight: bold;
		color: #58585a;
		padding: 9px 19px;
	}

	td {
		font-size: 16px;
		padding: 19px;
	}
	.tbody-content {
		border-bottom: solid 6px white;
	}
	
	@include themify($themes) {
		.tbody-content {
			background: themed('table-row-highlight-colour');
		
		}
	}
}

.documents-apply-button {
	margin-bottom: 0.5rem;
}

.documents-table {
	svg {
		cursor: pointer;
		
		&:hover {
			opacity: 0.5;
		}
	}

	.folder-row, .grid-table-row {
		cursor: pointer;
		transition: 200ms ease;

		svg {
			transition: 200ms ease;
		}

		&:hover {

			@include themify($themes) {
				background-color: themed('table-row-highlight-colour-hover') !important;
			}
		}
	}

	.column-3 {
		display: none;
	}

	.column-4 {
		justify-self: end;
		padding-right: 10px;
	}

	.grid-table-column.column-1 {
		width: 100%;
		font-weight: 600;
	}

	@media screen and (max-width: $breakpoint-xl) {
		.grid-table-row {
			grid-template-columns: 1fr 120px !important;
			padding: 9px 11px;
			grid-gap: 2px;
			
			&.no-tags {
				padding: 20px 11px;
			}
		}

		.column-1 {
			grid-column-start: 1;
			grid-column-end: span 1;
			@include ellipse;
			
			.document-tag {
				display: none;
			}
		}

		.column-2 {
			grid-column-start: 2;
			grid-column-end: span 1;
			grid-row-start: 1;
			text-align: right;
			width: auto;
			opacity: 0.7;
		}

		.column-3 {
			display: block;
			grid-column-start: 1;
			grid-column-end: span 1;
			grid-row-start: 2;

			.document-tag {
				&:first-of-type {
					margin-left: 0;
				}
			}
		}
	
		.column-4 {
			display: none;
		}
	}
}
