@import "../styles/Colours.scss";

.portal-alert {
	position: fixed;
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #{$SilverGrey}50;
	transition: top 0.5s, left 0.5s, opacity 0.5s, width 0.5s, height 0.5s;
	top: 0px;
	left: 0px;
	opacity: 1;
	width: 100%;
	height: 100%;
	.content {
		transform: scale(1);
		transition: transform 0.5s;
		background-color: white;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 40px 30px;

		.text {
			text-align: center;
			font-size: 24px;
			margin-bottom: 24px;
			font-weight: 600;
		}
		.sub-text {
			text-align: left;
			font-size: 14px;
			margin-bottom: 20px;
			font-weight: 500;
			opacity: 0.7;
		}
	}
	&.closed {
		top: 50vh;
		left: 50vw;
		opacity: 0;
		width: 0px;
		height: 0px;
		.content {
			transform: scale(0);
		}
	}
	&.mobile {
		.content {
			width: 80vw;
		}
	}
	&.desktop {
		.content {
			width: 540px;
		}
	}
}
