@import "../styles/Colours.scss";
@import "../../themes/themes.scss";

.range-bar-container {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 3px;
    height: 16px;
    z-index: 1;

    @include themify($themes) {
        background-color: themed('range-bar-container-colour');

        .range-bar, .range-bar-variance {
            transition: $transition;
            background-color: themed('range-bar-colour');
        }
    }

    .range-bar-tooltip {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    .range-bar {
        height: 21px;
        width: 4px;
        border-radius: 10px;
    }

    .range-bar-variance {
        // position: absolute;
        height: 14px;
        border-radius: 3px;
        opacity: 0.4;
        width: 100%;
        // left: 0;
        // top: 50%;
        // transform: translateY(-50%);
    }

    .hobson-logo-range-bar {
        width: 20px;
        height: 20px;
        cursor: default;
    }

    &.warning {
        .range-bar, .range-bar-variance {
            background-color: #f7a43d;
        }
        @include themify($themes) {    
            .range-bar, .range-bar-variance {
                background-color: themed('range-bar-warning-colour');
            }
        }
    }
}
