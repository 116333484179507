@import "../../../../themes/themes.scss";

.comment-block {
    &.replies-showing {
        .comment-outer-wrapper:not(.sub-comment) {
            @include themify($themes) {
                background-color: themed('notification-hover-colour');
            }
    
            .replies-button {
                opacity: 1;
            }
    
            .replies-expand-icon {
                transform: rotate(180deg);
            }
        }

        &:hover {
            .sub-comment {
                @include themify($themes) {
                    background-color: themed('notification-hover-colour');
                }
            }
        }
    }

    .comment-outer-wrapper {
        padding: 12px;
    
        .replies-expand-icon {
            transition: 200ms ease;
        }
    
        .menu-trigger {
            width: 16px;
            height: 16px;
            cursor: pointer;
            opacity: 0.4;
            transition: 100ms;
    
            &:hover {
                opacity: 1;
            }
        }
    
        .provider-initials-wrapper {
            margin-right: 0;
            flex-shrink: 0;
            max-width: 30px;
            width: 30px;
            height: 30px;
        }
        
        .comment-user-details {
            flex-wrap: wrap;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            flex: 1 1;
        
            p {
                margin: 0;
                font-size: 1rem;
            }
        
            .comment-date {
                font-size: 10.5px;
                opacity: 0.5;
                line-height: 1;
            }
        }
    
        .comment-header {
            margin-bottom: 8px;
        }
    
        border-bottom: 1px solid;
    
        @include themify($themes) {
            border-color: themed('hr-colour');
        }
    
        .comment-wrapper {
            p {
                margin: 0;
            }
        }
    
        .reply-button {
            opacity: 0.5;
            font-size: 13px;
            margin: 0;
            cursor: pointer;
    
            &:hover {
                opacity: 1;
            }
        }
    
        .reply-section {
            .portal-label.desktop.portal-label {
                margin-bottom: 4px;
            }
    
            .portal-label.desktop input {
                padding: 8px;
            }
    
            span {
                cursor: pointer;
                opacity: 0.6;
                font-size: 15px;
    
                &:hover {
                    opacity: 1;
                }
            }
        }
    
        .replies-button {
            display: inline-flex;
            width: fit-content !important;
            opacity: 0.5;
            font-size: 13px;
            margin: 0;
            cursor: pointer;
    
            svg {
                width: 18px;
                height: 18px;
            }
    
            &:hover {
                opacity: 1;
            }
        }
    }
    
    .sub-comment {
        padding-left: 28px;
    }
}