@import "../../themes/themes.scss";
.notification-list-container {
    width: 350px;
    position: absolute;
    top: 100%;
    right: -8px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 6px rgba(#000, 0.16);
    transform: translateY(0);
    visibility: hidden;
    max-height: 473px;
    overflow-y: auto;
    opacity: 0;
    z-index: 10;
    transition: opacity 200ms, visibility 400ms, transform 200ms;

    .notification-li-time {
        margin-top: 4px;
    }

    &.notifications-open {
        transform: translateY(8px);
        visibility: visible;
        opacity: 1;
    }
    
    .notification-list-header {
        padding: 17px;
    }
    .notification-li {
        padding: 15px 17px;
        cursor: pointer;
        position: relative;
        @include themify($themes) {
            border-top: 1px solid themed('lightest-colour');
        }
        &:hover {
            @include themify($themes) {
                background-color: rgba(themed('lightest-colour'), 0.3);
            }
        }
        &.unread-notification {
            font-weight: bold;
            @include themify($themes) {
                background-color: rgba(themed('lightest-colour'), 0.6);
            }
            &:hover {
                @include themify($themes) {
                    background-color: rgba(themed('lightest-colour'), 1);
                }
            }
            .notification-li-indicator {
                position: absolute;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                right: 17px;
                top: 50%;
                transform: translateY(-50%);
                @include themify($themes) {
                    background-color: themed('primary-colour');
                }
            }
        }

        .notification-li-delete {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 18px;
            cursor: pointer;
            @include themify($themes) {
                color: themed('light-grey');
                fill: themed('light-grey') !important;
            }
            
            &:hover {
                @include themify($themes) {
                    color: themed('primary-colour');
                }
            }

            @media screen and (max-width: 1200px){
                font-size: 22px !important;
            }
        }
    }
    .notification-li-label {
        width: 92%;
        line-height: 1.2;
        margin-top: 4px;
        font-size: 13px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        @include themify($themes) {
            color: themed('secondary-colour');
        }   
    }
    .notification-li-heading{
        width: 92%;
        line-height: 1.2;
        font-size: 16px;
        // display: -webkit-box;
        // -webkit-line-clamp: 3;
        // -webkit-box-orient: vertical;
        // overflow: hidden;
        // text-overflow: ellipsis;
        @include themify($themes) {
            color: themed('secondary-colour');
        }   
    }
    .notification-li-time {
        width: 92%;
        opacity: 0.5;
        font-size: 0.85rem;
        font-weight: 400;

        @include themify($themes) {
            color: themed('primary-colour');
        }
    }
    .notification-li-header{
        width: 92%;
        display: flex;
        margin-bottom: 5px;
        align-items: center;
    }
    .notification-li-service-type{
        background-color: $GlacierTeal;
        color: #fff;
        padding: 0px 5px;
        border-radius: 10px;
        font-weight: 400;
        font-size: 11px;
        height: 16px;
    }
    .notification-li-account{
        margin-left: 5px;
        font-size: 13px;
        font-weight: 400;
        color: $GlacierTeal;
    }
}