@import "./Colours.scss";
@import "./Variables.scss";
@import "../../themes/themes.scss";

.details-confirm-container {
	display: none;
}

.details-item-outer {
	margin-bottom: 20px;

	@include themify($themes) {
		&.read-only {
			.details-item-resting  {
				background-color: themed('input-disabled-colour') !important;
				border-color: themed('input-disabled-border-colour') !important;
				color: themed('input-disabled-text-colour') !important;
			}
		}
	}

	label {
		text-transform: uppercase;
		font-weight: bold;
		line-height: 2em;
		letter-spacing: 1px;
		font-size: 14px;
		margin-bottom: 10px;

		@include themify($themes) {
			color: themed('input-label-colour');

			i {
				color: themed('primary-colour');
			}
			svg path {
				fill: themed('primary-colour');
			}
		};
	}

	.input-validation-prefix {
		margin: 0;

		.prefix-input {
			padding: 0;
		}
	}

	.details-item-resting, input, .dropdown-value-wrapper {
		box-sizing: border-box;
		border-radius: 5px;
		font-weight: normal;
		padding: 14px 20px;
		border-radius: 5px;
		font-size: 15px;
		line-height: 15px;
		width: 100%;
		min-height: 49px;
	
		@include themify($themes) {
			background-color: themed('input-colour');
			border: themed('input-border');
			
			&.disabled, &:disabled {
				background-color: themed('input-disabled-colour') !important;
				border-color: themed('input-disabled-border-colour') !important;
				color: themed('input-disabled-text-colour') !important;
			}
			
			&:focus {
				background-color: themed('search-input-colour-focused');
				border-color: themed('search-input-border-colour-focused');
			}
		};


		.details-item-icons {
			display: none;
		}
	}

	// edit mode
	&.edit-mode {
		background-color: transparent;
		border: none;
		padding: 0;
		margin: 0;
		margin-bottom: 20px;
		border-radius: 0;

		label {
			font-size: 14px;
			margin-bottom: 10px;
		}

		.details-item-buttons {
			display: none;
		}

		.dropdown-value-wrapper  {
			margin-top: 0;
			display: flex;
			align-items: center;
			justify-content: space-between;
			max-height: 49px;

			&:hover {
				box-shadow: none;
			}

			&.placeholder-value {
				opacity: 1;
			}

			&.dropdown-value__active {
				@include themify($themes) {
					background-color: themed('search-input-colour-focused') !important;
					border-color: themed('search-input-border-colour-focused') !important;

					i {
						color: themed('search-input-indicator-colour');
					}
				};
			}
		}

		.dropdown-selection-list-container {
			z-index: 90;

			.dropdown-selection-list {
				box-shadow: none;
				@include themify($themes) {
					background-color: #fff !important;
					border: 1.5px solid themed('input-focused-colour') !important;

					li {
						color: themed('search-input-option-colour');
						
						&:hover {
							background-color: themed('search-input-option-hover-colour');
						}
					}
				};
			}
		}
	}

	// mobile edit mode
	.edit-mode-content, .single-phone-edit, .addable-location-field {
		.input-validation-text {
			@include themify($themes) {
				border-width: 1.5px !important;
				border-left-color: themed('input-placeholder-colour') !important;
				z-index: 1;

				&:focus {
					border-left-color: themed('search-input-border-colour-focused') !important;
				}
			}
		}

		.extension-input {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;

			@include themify($themes) {
				border: themed('input-border') !important;
				border-left-color: themed('input-placeholder-colour') !important;

				&:focus {
					border-color: themed('search-input-border-colour-focused') !important;
					border-left-color: themed('search-input-border-colour-focused') !important;
				}
			}
		}

		.token-field {
			.token-field__placeholder, .suggestion-text {
				@include themify($themes) {
					color:  themed('text-colour') !important;
				}
			}

			.token-field__control {
				height: 49px;

				@include themify($themes) {
					background-color: themed('input-colour');
					border: themed('input-border') !important;

					&:focus-within {
						z-index: 2;
						background-color: #fff !important;
						border-color: themed('search-input-border-colour-focused') !important;
					}
				}

				.token-field__input {
					input  {
						width: 100% !important;
						min-height: auto;
						height: 34px;
						box-shadow: none;
					}
				}

				.token-field__single-value {
					@include themify($themes) {
						color: themed('text-colour')  !important;
					}
				}
			}
		}

		.token-field__menu {
			z-index: 90;

			.token-field__menu-list {
				@include themify($themes) {
					.token-field__option {
						color: themed('search-input-option-colour')  !important;

						span {
							color: themed('search-input-option-colour')  !important;
						}

						&.token-field__option--is-focused {
							background-color: themed('lightest-colour')  !important;
						}
					}
				}
			}
		}		
	}

	.single-phone-edit {
		.token-field {
			.token-field__control {
				width: 56px;
			}
		}
	}

	.addable-location-field {
		.token-field {
			.token-field__control {
				width: 100%;
			}
		}
	}

	.overseas-toggle-wrapper {
		display: none;
	}

	.single-date-edit {
		button {
			&:nth-child(2) {
				display: none;
			}
		}

		.date-field-outer {
			width: 100%;
			height: 49px;
			margin: 0;
		}

		@include themify($themes) {
			.date-field-outer-disabled {
			cursor: default;
			background-color: themed('input-disabled-colour') !important;
			border-color: themed('input-disabled-border-colour') !important;
			color: themed('input-disabled-text-colour') !important;
			}
		}

		.calendar-trigger-disabled {
			cursor: default;
		}

		.calendar-wrapper {
			.react-calendar button {
				@include themify($themes) {
					color: themed('search-input-option-colour') !important;
				}
			}
		}
	}

	&.checkbox-field-outer {
		margin-bottom: 0;

		.checkbox-field {
			transform: translateY(10px);
		}

		.details-checkbox-resting {
			.checkbox-field {
				@include themify($themes) {
					color:  themed('text-colour');
					
					i {
						font-size: 1.2rem;
						color: themed('primary-colour') !important;
					}
				}
			}
		}
	}

	// Validation styling
	.crm-error-message {
		&.is-invalid {
			input, .dropdown-value-wrapper, .date-field-outer, .token-field__control {
				@include themify($themes) {
					color: themed('text-colour') !important;
					border-color: themed('warning-colour') !important;
					background-color: themed('warning-colour-light') !important;

					&:focus, &:focus-within {
						background-color: #fff !important;
						border-color: themed('warning-colour') !important;
					}
				}
			}
			
			.date-field-outer {
				.calendar-trigger {
					@include themify($themes) {
						color: themed('warning-colour') !important;
					}
				}

				input {
					@include themify($themes) {
						background-color: transparent !important;

						&:focus {
							background-color: transparent !important;
						}
					}
				}
			}

			.token-field__control {
				input {
					@include themify($themes) {
						background-color: transparent !important;

						&:focus {
							background-color: transparent !important;
						}
					}
				}
			}

		}
	}

	.input-validation-warning-message {
		display: flex;
		align-items: center;
		gap: 4px;
		margin-top: 8px !important;

		.input-validation-error-message, p {
			margin: 0;
			font-size: 14px;
		}
		.input-validation-warning-icon {
			margin: 0;
			transform: translateY(1px);

			i, p {
				font-size: 0.9rem;
				@include themify($themes) {
					color: themed('warning-colour') !important;
				}
			}
		}
	}
	
	.phone-number-edit-container {
		.input-invalid .token-field__control, .input-validation-text-invalid {
			@include themify($themes) {
				color: themed('text-colour') !important;
				border-color: themed('warning-colour') !important;
				background-color: themed('warning-colour-light') !important;

				&:focus {
					border-left-color: themed('warning-colour') !important; 
					background-color: #fff !important;
				}

				&:focus-within {
					border-color: themed('warning-colour') !important;
					background-color: #fff !important;
				}
			}
		}
	}

	.portal-tooltip-popup {
		text-transform: capitalize;
    	letter-spacing: normal;
	}

	.divided-value-field-item {
		display: flex;
		flex-direction: row-reverse;

		.tooltip-container {
			display: none !important;
		}

		.pending {
			display: none !important;
			margin-left: 0;

			@include themify($themes) {
				color: themed('primary-colour');
			}
		}

		.detail-popup, .tooltip-popup {
			background-color: #fff;
			top: -40px;

			@include themify($themes) {
				color: themed('text-colour');

				&:after {
					display: none;
				}
			}
		}
	}

	.token-field__menu-notice--no-options {
		@include themify($themes) {
			background-color: themed('lightest-colour');
			color: themed('darkest-colour');
			
			.create-suggestion {
				color: themed('darkest-colour');
			}
		}
	}
}