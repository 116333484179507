@import "../../themes/themes.scss";

.radio-input-field {
	margin-right: 12px;
	cursor: pointer;

	&.onbording-radio-button {
		svg {
			width: 14px;
			margin-right: 3px;

			@include themify($themes) {
				fill: themed('radio-button-border') !important;
			}
		}

		&.checked {
			svg {
				width: 14px;
				margin-right: 3px;

				@include themify($themes) {
					fill: themed('radio-button-active') !important;
				}

			}
		}

		&.disabled {
			cursor: default;
		}
	}

	svg {
		width: 18px;
		margin-right: 3px;

		@include themify($themes) {
			fill: themed('radio-button-border') !important;
		}
	}

	&:last-of-type {
		margin-right: 0;
	}
}