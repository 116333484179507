@import "../styles/Colours.scss";
@import "../../themes/themes.scss";

.split-field-input {
	box-sizing: border-box;
	border-radius: 5px;
	font-weight: normal;
	width: 100%;
	padding: 14px 20px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
	gap: 8px;
	font-size: 15px;
	line-height: 15px;
	flex-wrap: nowrap;
	

	.sfi-value {
		padding-right: 14px;
		border-right: 1px solid #B5B5B5;
		line-height: initial;

		&:first-of-type {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		&:nth-of-type(2) {
			flex-shrink: 0;
		}

		&:last-of-type {
			border: none;
			padding-right: 0;
		}
	}

	@include themify($themes) {
		border: themed('input-border');
		transition: themed('input-transition');
		background-color: themed('input-colour');

		&:focus-within {
			outline: none;
			background-color: themed('input-focused-colour');
			border: themed('input-focused-border');
			box-shadow: themed('input-shadow');
		}

		.placeholder {
			color: themed('input-placeholder-colour');
			opacity: 0.4;
		}

		&.disabled-field {
			// background-color: themed('input-disabled-colour');
			// border: themed('input-disabled-border-colour');
			// color: themed('input-disabled-text-colour');
		}

		.sfi-icon {
			color: themed('text-colour');
			opacity: 0.5;

			&:hover {
				opacity: 1;
				color: themed('warning-colour');
			}
		}
	}

}