@import "./Colours.scss";
@import "./Variables.scss";
@import "../../themes/themes.scss";

* {
	font-family: 'Metropolis', helvetica, arial, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	@include themify($themes) {
		color: themed('header-colour');
	}
	margin: 0px;
	font-weight: 600;
}

p {
	font-size: 15px;

	@include themify($themes) {
		color: themed('text-colour');
	}
}

h1 {
	font-size: 45px;
}

h2 {
	font-size: 40px;
}

h3 {
	font-size: 30px;
}

h4 {
	font-size: 22px;
}

h5 {
	font-size: 18px;

	@include themify($themes) {
		color: themed('small-header-colour');
	}
}

h6 {
	font-size: 15px;
}

span, li {
	@include themify($themes) {
		color: themed('text-colour') !important;
	}
}

.filter-heading {
	font-size: 12px;
	letter-spacing: 1px;
	font-weight: 600;
	margin-bottom: 10px;
	text-transform: uppercase;
	
	@include themify($themes) {
		color: themed('filter-heading-colour');
	}
}

.sub-header-small {
	font-size: 12px;
	letter-spacing: 1px;
	font-weight: bold;
	
	@include themify($themes) {
		color: themed('subheader-colour');
	}
}

.table-header, th {
	font-size: 15px;
	text-transform: capitalize;
	font-weight: 400;

	@include themify($themes) {
		font-family: inherit;
		color: themed('table-headers-colour');
	}
}

.tr-expanded-header {
	font-size: 12px;
	letter-spacing: 1px;
	@include themify($themes) {
		color: themed('table-expanded-row-header');
	}
}

@media all and (max-width: $breakpoint-sm) {
	h1 {
		font-size: 31px;
	}

	h2 {
		font-size: 26px;
	}

	h3 {
		font-size: 20px;
	}

	h4 {
		font-size: 16px;
	}

	h5 {
		font-size: 10px;
	}

	h6 {
		font-size: 11px;
	}
}
