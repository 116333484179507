@import "../../themes/themes.scss";

.error-message {
	padding: 20px 0 25px 0;
	display: flex;
	align-items: center;
	justify-content: center;

	&.with-icon {
		@media screen and (max-width: 625px){
			flex-direction: column;
		}

		h4 {
			opacity: 1 !important;
			font-size: 15px;
			@include themify($themes) {
				color: themed('secondary-colour');
			}
		}
	}

	svg {
		margin-right: 5px;
		font-size: 21px;

		@include themify($themes) {
			color: themed('primary-colour');
		}
	}

	h4 {
		font-size: 18px;
		opacity: 0.8 !important;
	}
}