@import "../../themes/themes.scss";

.portal-label.filter-input {
	.input-wrapper {
		position: relative;
		
		input {
			padding: 8px 10px;
			@include themify($themes) {
				border: themed('input-border');
			}
	
			&::-webkit-search-cancel-button{
				display: none;
			}
	
			&::placeholder {
				@include themify($themes) {
					color: themed('filter-input-placeholder');
					opacity: 1;
				}
			}
			
			&:focus {
				@include themify($themes) {
					background-color: themed('search-input-colour-focused');
					border-color: themed('search-input-border-colour-focused');
				}
			}
		}
	
		svg {
			position: absolute;
			left: 10px;
			top: 50%;
			transform: translateY(-50%);
	
			@include themify($themes) {
				color: themed('filter-input-placeholder');
			}
		}

		&:focus-within {
			svg {
				@include themify($themes) {
					color: themed('search-input-focused-icon');
				}
			}
		}
	}
}
