@import "../../../themes/themes.scss";

.standing-order-value-group {
	margin-right: 20px;

	&:last-of-type {
		margin-right: 0;
	}

	label {
		font-size: 12px;
		opacity: 0.7;
		margin-bottom: 0;
	}

	.order-value {
		font-size: 16px;
		font-weight: 600;
		margin-top: -3px;
	}

	// Specific widths for group types.
	&.next-date, &.end-date, &.start-date, &.next-run {
		width: 115px;
	}

	&.account-number {
		width: 160px;
	}

	&.type {
		width: 225px;
	}

	@media screen and (min-width: 800px){
		&.amount {
			margin-left: auto;
			text-align: right;
		}
	}
}