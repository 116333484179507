@import "../../themes/themes.scss";

.back-to-top {
	position: fixed;
	right: 20px;
	bottom: 10px;
	opacity: 0;
	visibility: hidden;
	min-width: auto !important;
	width: 120px;
	transition: 200ms ease;
	box-shadow: 0 0 6px rgba(#000, 0.1);
	font-weight: 600;
	z-index: 100;

	@include themify($themes) {
		background-color: themed('primary-colour');
		border-color: themed('primary-colour');
	}

	&.show-button {
		opacity: 1;
		visibility: visible;
		bottom: 20px
	}

	&:hover {
		transform: scale(1.03);
		
		@include themify($themes) {
			background-color: lighten(themed('primary-colour'), 2%);
			border-color: lighten(themed('primary-colour'), 2%);
		}
	}
}