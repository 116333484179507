@import "../../../../core-components/styles/Colours.scss";
@import "../../../../themes/themes.scss";

#portal-overview-asset-groups {
	width: 100%;
	border-collapse: collapse;
	transition: $transition;

	@include themify($themes) {
		tbody {
			border-bottom: themed("comparison-table-border");
		}

		.tbody--first {
			border-top: themed("comparison-table-border");
		}
	}

	&.mobile {
		font-size: 12px;
		.td-graphic {
			min-width: 74px;
		}
	}

	&.desktop {
		font-size: 16px;
		.td-graphic {
			min-width: 130px;
		}
	}

	td {
		font-weight: normal;
		padding: 8px 0;
		background-color: transparent;
		
		@include themify($themes) {
			border-top-color: themed('hr-colour');
			border-bottom-color: themed('hr-colour');
		}
	}

	.td-name {
		text-align: left;
		padding-left: 2px;
	}

	.td-value {
		text-align: center;
		padding: 0 5px;
	}

	.td-perc {
		text-align: right;
	}

	.td-graphic {
		padding-left: 1em;
		padding-right: 2px;
	}

	img {
		width: 90px;
	}
}

.navigator-asset-allocation {
	@include themify($themes) {
		.defensive-vs-growth {
			margin-top: 5px;

			.range-bar-tooltip {
				z-index: 101 !important;
			}

			.graphic {
				height: 50px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				.range-bar {
					height: 16px;
					background: themed("comparison-bar-colour-1");
					border-radius: 5px 0px 0px 5px;
				}
				.range-bar-variance {
					height: 29px;
					border-radius: 5px;
				}
				.range-bar-container {
					background: themed("comparison-bar-colour-2");
					border-radius: 5px;
					height: 16px;
				}
			}
			.legend {
				display: flex;
				align-items: center;
				.defensive, .growth {
					width: 20px;
					height: 10px;
					margin-right: 0.5em;
					border-radius: 10px;
				}
				.defensive {
					background: themed("comparison-bar-colour-1");
				}
				.growth {
					background: themed("comparison-bar-colour-2");
					margin-left: 1em;
				}
			}
		}
	}
}

#comparison-bar {
	width: 100%;
	height: 42px;
	border-radius: 5px;
	transition: $transition;

	@include themify($themes) {
		background-color: themed("comparison-bar-colour-1");

		div {
			background-color: themed("comparison-bar-colour-2");
		}
	}

	div {
		height: 100%;
		border-radius: 5px 0px 0px 5px;
		transition: width 0.5s;
	}
}

.asset-groups-legend {
	transition: $transition;

	@include themify($themes) {
		.circle-1 {
			background-color: themed("comparison-bar-colour-2");
		}

		.circle-2 {
			background-color: themed("comparison-bar-colour-1");
		}
	}

	&.mobile {
		font-size: 12px;
	}

	&.desktop {
		font-size: 16px;
	}
}
