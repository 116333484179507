@import "@threeskye/core-components/dist/styles/Variables.scss";

.details-item-outer {
	transition: 200ms ease;
	margin-bottom: 23px;
	position: relative;

	&.editable {
		cursor: pointer;

		label {
			cursor: pointer;
		}
	}

	label {
		margin-bottom: 7px;
		display: flex;
		align-items: center;
	}

	.read-only-field {
		pointer-events: none;
		cursor: default;
		background-color: rgba($colour-form-input, 0.2);
	}

	&.edit-mode {
		background-color: #212843; //$colour-tertiary REQUIRES core-component deploy
		border: dashed 2px #4c5072; //$border-tertiary REQUIRES core-component deploy
		padding: 14px;
		border-radius: 6px;

		label {
			margin-bottom: 0;
		}

		.input-like {
			margin-top: 10px;
		}

		.read-only-field {
			border-color: rgba($colour-form-input, 0.3);
		}
	}

	.edit-mode-content {
		input {
			margin-bottom: 19px;

			&:last-of-type {
				margin-bottom: 0;
			}
		}

		.checkbox {
			input {
				margin: 0;
				margin-right: 7px;
			}
		}
	}

	&.read-only {
		.details-item-resting {
			background-color: rgba($colour-secondary, 0.15);
			cursor: default;
		}	
	}


	.input-validation-warning-message {
		font-size: 1rem;
	}

	.pending {
		color: $colour-primary;
		vertical-align: middle;
		font-size: 0.9rem;
		margin-left: 3px;
		margin-bottom: 1px;
	}
	
	.checkbox {
		display: flex;
		align-items: center;
		flex-direction: row-reverse;
		justify-content: flex-end;
		
		input {
			margin-right: 7px;
			width: auto	;
		}
	}

}

.details-item-buttons {
	margin-top: 22px;
	display: flex;
	align-items: center;
}

.details-item-resting {
	background-color: rgba($colour-secondary, 0.5);
	cursor: pointer;
	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 16px;
	padding: 9px 10px;
	min-height: 38px;

	&.container {
		background-color: inherit;

		>div {
			background-color: rgba($colour-secondary, 0.3);
			border-radius: 6px;
		}
	}
	.edit-icon, .delete-icon {
		display: none;
		cursor: pointer;
	}
	&:hover {
		.edit-icon, .delete-icon {
			display: block;
		}
	}

	.unverified-status-icon, .electronic-status-icon, .manual-status-icon {
		opacity: 1;
		padding-left: 6px;
		border-left: 2px solid rgba(#fff, 0.1);
		margin-right: 0;
	}

	.unverified-status-icon {
		color: $colour-warning;
	}
	.electronic-status-icon {
		color: $colour-npe;
	}
	.manual-status-icon {
		color: $colour-account;
	}
}

.details-item-icons {
	display: flex;
	align-items: center;
	
	i {
		font-size: 20px !important;
		margin-right: 6px;

		&:last-child {
			margin-right: 0;
		}

		&:hover {
			opacity: 0.5;
		}
	}

	.edit-icon {
		color: $colour-primary;
	}

	.delete-icon {
		color: $colour-warning;
	}

	.review-complete {
		display: flex;
		align-items: center;
		font-size: 11px;

		i {
			color: $colour-primary;
			margin-left: 3px;
		}
	}
	.review-button-large {
		font-size: 0.7rem;
		button{
			padding: 0 5px;
		}	
	}
}

.details-item-delete-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba($colour-background, 0.9);
	display: flex;
	align-items: center;
	justify-content: center;
}

.details-item-overlay-inner {
	text-align: center;

	h1 {
		font-size: 1.2rem;
	}

	.details-item-delete-overlay-buttons {
		margin-top: 10px;
		display: flex;
		justify-content: center;
	}
}