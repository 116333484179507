@import "../../themes/themes.scss";

.portal-text-area {
	width: 100%;

	textarea {
        font-size: 15px;
        line-height: 15px;
		width: 100%;
		@include themify($themes) {
			background-color: themed('input-colour');
		}

		&.mobile {
			padding: 15px;
		}
		&.desktop {
			padding: 17.5px;
		}
	}
}
