
@import "../../themes/themes.scss";

.toggle-button-field {
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	.filter-heading {
		width: 100%;
		margin-bottom: 17px;
	}
	
	label {
		margin-right: 9px;
		cursor: pointer;
	}
}