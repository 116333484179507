@import "../../../../core-components/styles/Colours.scss";
@import "../../../../core-components/styles/Variables.scss";
@import "../../../../themes/themes.scss";

#customer-portal-page {
	&.mobile {
		#save-changes-banner {
			width: 100vw;
			height: 80px;
		}
	}
}

#save-changes-banner {
	width: calc(100vw - 344px);
	position: absolute;
	right: 0;
	bottom: 0;
	background-color: #fff;
	box-shadow: 0 -2px 4px rgba(#000, 0.15);
	display: flex;
	justify-content: flex-start;
	align-items: center;
	z-index: 80;
	transition: 300ms ease;
	opacity: 0;
	height: 0;
	overflow: hidden;

	&.display-banner {
		opacity: 1;
		height: 108px;
	}
	
	.save-changes-banner-inner {
		width: 100%;
		max-width: 1267px;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 16px;
	}
}
