.tooltip-popup-wrapper {
	padding: 12px;
	font-size: 14px;
    
	&.padding-xxs {
		padding: 0px
	}
	
	&.padding-xs {
		padding: 4px;
	}

	&.padding-s {
		padding: 10px;
	}

	&.padding-l {
		padding: 10px;
	}

	&.padding-xl {
		padding: 10px;
	}

	&.size-s {
		font-size: 12px;
	}

	&.size-l {
		font-size: 16px;
	}
}


.tooltip-with-icon {
	display: flex;
	align-items: center;

	svg {
		width: 16px;
		margin-right: 8px;
		color: #fff;
	}
}