@import "./Variables.scss";

// Override bootstrap variables here. Variables are located in node_modules/bootstrap/scss/_variables.scss.

// Grid
$grid-gutter-width: 29px !default;

// Containers
$container-max-widths: (
	xs: $container-max-size,
) !default;

$grid-breakpoints: (
	xs: $breakpoint-xs,
	sm: $breakpoint-sm,
	md: $breakpoint-md,
	lg: $breakpoint-lg,
	xl: $breakpoint-xl,
) !default;

$spacers: ();

//Spacer
$spacers: map-merge(
	(
		0: 0,
		1: $spacer * 0.25,
		2: $spacer * 0.5,
		3: $spacer,
		4: $spacer * 1.5,
		5: $spacer * 3,
		6: $spacer * 4,
		7: $spacer * 4.5,
		8: $spacer * 5,
		9: $spacer * 5.5,
		10: $spacer * 6,
		11: $spacer * 6.5,
		12: $spacer * 7,
		13: $spacer * 7.5,
		14: $spacer * 8,
		15: $spacer * 8.5,
		16: $spacer * 9,
	),
	$spacers
);

@import "../../../node_modules/bootstrap/scss/bootstrap.scss";
