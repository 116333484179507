@import "../../themes/themes.scss";
@import "../styles/Variables.scss";

.date-range-inputs {
	display: flex;
	align-items: center;
	justify-content: space-between;

	.date-range-arrow {
		transform: translateY(11px);

		@include themify($themes) {
			color: rgba(themed('text-colour'), 0.65);
		}
	}

	&.no-labels {
		.date-range-arrow {
			transform: translateY(0);
		}
	}

	&.no-margins {
		.portal-label {
			margin-bottom: 0;
		}
	}
	
	.filter-input {
		width: 45%;
	}
	
	@media screen and (min-width: $breakpoint-md){
		.date-field {
			width: 48%;
		}
	}

	@media screen and (max-width: $breakpoint-md) {
		flex-wrap: wrap;

		.date-range-arrow {
			display: none;
		}

		.date-field {
			width: 100%;

			&:last-of-type {
				margin-top: 0.9rem;
			}
		}
	}
}
