@import "../../themes/themes.scss";

.banner-navlinks-container {
	width: 100%;
	padding: 0 30px 11px 30px;
	height: 70px;
	display: flex;
	align-items: flex-end;
	box-shadow: 0 5px 8px rgba(#000, 0.06);
	z-index: 9;

	@include themify($themes) {
		background-color: themed("banner-colour");
		// border-top: 1px solid themed('banner-border');
	}

	.banner-navlink {
		font-size: 17px;
		font-weight: 600;
		opacity: 0.5;
		padding: 0 11px 13px 11px;
		position: relative;
		cursor: pointer;
		transition: 200ms ease;
		margin-right: 10px;
		text-decoration: none;

		@include themify($themes) {
			color: themed("text-colour");
		}

		&::after {
			content: "";
			width: 100%;
			opacity: 0;
			height: 3px;
			position: absolute;
			bottom: -1px;
			left: 0;

			@include themify($themes) {
				background-color: themed("primary-colour");
			}
		}

		&:hover {
			opacity: 1;
			text-decoration: none;
		}

		&.active-option {
			opacity: 1;

			&::after {
				opacity: 1;
			}
		}
	}
}
