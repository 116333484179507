@import "../../../../../core-components/styles/Variables.scss";

.other-reports-table-wrapper {
	padding-bottom: 20px;	
}

.other-reports-table {	
	th {
		padding-bottom: 14px;

		&:nth-of-type(1) {
			min-width: 120px;
			padding-left: 0;
		}
		&:nth-of-type(2) {
			min-width: 180px;
			display: none;
		}
		&:nth-of-type(3) {
			min-width: 280px;
			display: none;
		}
		&:nth-of-type(4) {
			min-width: auto;
			text-align: right;
			display: none;
		}
	}

	tr {
		td {
			padding: 11px 0;
			padding-right: 5px;
			background-color: #fff !important;

			&:nth-of-type(2), &:nth-of-type(3) {
				display: none;	
			}

			&:nth-of-type(4) {
				.portal-button {
					margin-left: auto;
				}
			}
		}
	}

	.generate-button {
		.button-label {
			display: none;
		}

		svg {
			margin: 0;
		}
	}

	label {
		margin-bottom: 0;
	}

	@media screen and (max-width: $breakpoint-xl) {
		tr {
			td {
				padding: 9px 0;
			}
		}

		.generate-button {
			padding: 2px 4px;
			border-radius: 7px;
		}
	}

	@media screen and (min-width: $breakpoint-xl) {
		th {
			&:nth-of-type(2) {
				display: table-cell;
			}
			&:nth-of-type(3) {
				display: table-cell;
			}
			&:nth-of-type(4) {
				display: table-cell;
			}
		}

		tr {
			td {
				&:nth-of-type(2), &:nth-of-type(3) {
					display: table-cell;	
				}
			}
		}
	}

	@media screen and (min-width: 1450px){
		.generate-button {
			padding: 4px 10px;

			.button-label {
				display: block;
			}
	
			svg {
				margin: inherit;
			}
		}
	}

	&.table-disabled {
		.generate-button {
			opacity: 0.5;
			pointer-events: none;
		}
	}

	.or-single-date {
		width: 47%;
		margin-left: auto;
	}
}