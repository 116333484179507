@import "../../themes/themes.scss";

.table-header {
    &::selection {
        color: inherit;
        background-color: transparent;
    }
}

.text-right, .right-align {
    .sort-header {
        justify-content: flex-end;

        .slide-out-icon {
            opacity: 0;
            transition: 200ms ease;
            transform: translateX(18px);
    
            &.sort-ascending {
                transform: translateX(18px) rotate(0);
            }
        }
    
        .slide-in-icon {
            // transform: translateX(14px);
            opacity: 1;
            transition: 200ms ease;
    
            &.sort-ascending {
                transform: rotate(180deg);
            }
        }
    }
}
.text-left, .left-align {
    .sort-header {
      

        .slide-out-icon {
            opacity: 0;
            transition: 200ms ease;
            transform: translateX(18px);
    
            &.sort-ascending {
                transform: translateX(18px) rotate(0);
            }
        }
    
        .slide-in-icon {
            // transform: translateX(14px);
            opacity: 1;
            transition: 200ms ease;
    
            &.sort-ascending {
                transform: rotate(180deg);
            }
        }
    }
}

.sort-header { 
    cursor: pointer;
    display: flex;
    align-items: center;

    div {
        display: inline-block;
        transition: 200ms ease;
        
        @include themify($themes) {
            color: themed('table-headers-colour');
        }
    }

    svg {
        font-size: 16px;
        display: inline-block;

        @include themify($themes) {
            color: themed('primary-colour');
        }
    }

    .slide-out-icon {
        opacity: 0;
        transition: 200ms ease;
        transform: translateX(-10px);

        &.sort-ascending {
            transform: translateX(-10px) rotate(0);
        }
    }

    .slide-in-icon {
        transform: translateX(0);
        opacity: 1;
        transition: 200ms ease;

        &.sort-ascending {
            transform: translateX(0) rotate(180deg);
        }
    }

    &.sorting-active {
        div {            
            @include themify($themes) {
                color: themed('primary-colour');
            }
        }
    }

    .header-text-wrapper {
        &::selection {
            color: inherit;
            background-color: transparent;
        }
    }
}
.justify-flex-end{
    justify-content: flex-end;
}