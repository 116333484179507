@import "../../../../../themes/themes.scss";

#portfolio-local-value, #portfolio-current-nzd-value, #portfolio-holdings {
	width: 110px;
}

.portfolio-table-container {
	table {
		table-layout: fixed;
	}
}

.portfolio-asset-table-card {
	h3 {
		svg {
			cursor: pointer;
			transition: 200ms ease;
			
			@include themify($themes) {
				fill: themed('primary-colour');
			}

			&:hover {
				opacity: 0.5;
			}
		}
	}
}

.header-right{
	text-align: right;
	transform: translateX(16px);
	
}

.text-right{
	text-align: right;
}

.text-left {
	text-align: left;
	padding-left: 16px;
}

.in-page-anchor {
    display: block;
    position: relative;
    top: -100px;
    visibility: hidden;
}