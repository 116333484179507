@import "./Colours.scss";
@import "../../themes/themes.scss";
@import "./DateFieldOverrides.scss";

input,
textarea {
	box-sizing: border-box;
	border-radius: 5px;
	font-weight: normal;

	@include themify($themes) {
		border: themed('input-border');
		transition: themed('input-transition');

		&:focus {
			outline: none;
			background-color: themed('input-focused-colour');
			border: themed('input-focused-border');
			box-shadow: themed('input-shadow');
		}

		&::placeholder {
			color: themed('input-placeholder-colour');
			opacity: 0.4;
		}
	}
}