@import "../../../core-components/styles/Colours.scss";
@import "../../../core-components/styles/Variables.scss";
@import "../../../themes/themes.scss";

.maintenance-page-container {
	padding: 85px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	width: 100vw;
	height: 100vh;
	position: relative;

	.hobson-logo {
		position: absolute;
		left: 85px;
		top: 85px;
	}
}

.maintenance-content-container {
	max-width: 500px;
	text-align: center;

	p {
		font-size: 17px;
		line-height: 1.3;
	}

	.portal-button {
		margin: 0 auto;
		padding-left: 30px;
		padding-right: 30px;

		a {
			color: #fff;
			text-decoration: none;
		}
	}
}

.maintenance-icon {
	width: 95px;
	height: 95px;
}