@import "../../../../../themes/themes.scss";

#cash-local-value, #cash-current-nzd-value, #cash-holdings {
	width: 110px;
}

.cash-table-container {
	table {
		table-layout: fixed;
	}
	
	tbody {
		tr {
			&:nth-of-type(4n-1) {
				td {
					background-color: #fff !important;
				}
			}
		}
	}

	.expandable-row {
		&:hover {
			cursor: pointer;

			td {
				@include themify($themes) {
					background-color: themed('lightest-colour') !important;
				}
			}
		}
	}

	.expand-icon-col {
		text-align: right;
		width: 10px;
		
		svg {
			font-size: 19px;
			transition: 200ms ease;
			transform: translateY(-4px);

			@include themify($themes) {
				fill: themed('secondary-colour');
			}
		}
	}

	.expanded-row-active {
		.expand-icon-col {
			svg {
				transform: translateY(-2px) rotate(-180deg);
			}
		}
	}
}

.header-right{
	justify-content: right;
	text-align: right;
	// transform: translateX(16px);
}

.text-right{
	text-align: right;
}

.text-left {
	text-align: left;
	padding-left: 16px;
}

.in-page-anchor {
    display: block;
    position: relative;
    top: -100px;
    visibility: hidden;
}