@import "../../core-components/styles/Colours.scss";
@import "../../themes/themes.scss";

.asset-table-mobile {
	padding: 13px 20px;
	background-color: rgba($GlacierTeal, 0.1);
	border-radius: 6px;
	margin-bottom: 6px;

	.portal-tooltip-popup {
		display: none;
	}

	.range-bar-container {
		border-radius: 6px;

		// @include themify($themes) {
		// 	background-color: rgba(themed('primary-colour'), 0.5);
		// }
	}

	div {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.asset-table-mobile__percentage {
		width: 70px;
	}
}

.asset-table-mobile__top {
	margin-bottom: 5px;
	font-size: 14px;
	color: $OceanBlue;
}

.asset-table-mobile__bar {
	width: 100%;
}