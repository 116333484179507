@import "../styles/Colours.scss";
@import "../../themes/themes.scss";
@import "../styles/Variables.scss";

.portal-button {
	line-height: 1em;
	border: none;
	font-size: 12px;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 2px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: background-color 0.25s, color 0.25s, opacity 0.25s;
	outline: none !important;
	font-weight: bold;
	height: 48px;

	svg {
		font-size: 16px;
	}

	@include themify($themes) {
		color: themed('button-text-colour');
		background-color: themed('button-colour-primary');
		border: themed('button-border-primary');
		border-radius: themed('button-border-radius');
		text-transform: themed('button-text-transform');

		&:hover {
			background-color: themed('button-colour-hover');
			border-color: themed('button-colour-hover');
		}

		&.button-colour-secondary {
			color: themed('secondary-colour');
			background-color: themed('button-colour-secondary');
			border: themed('button-border-secondary');

			&:hover {
				background-color: themed('button-colour-hover');
				border-color: themed('button-colour-hover');
				color: themed('button-colour-secondary');
			}
		}

		&.button-primary-highlight {
			color: themed('button-text-colour');
			background-color: themed('button-colour-primary-highlight');
			border: themed('button-colour-primary-highlight');
			
			&:hover {
				background-color: themed('button-colour-primary');
				border: themed('button-colour-primary-highlight');
			}
		}

		&.button-colour-grey {
			background-color: themed('subheader-colour');
			border: themed('subheader-colour');
		}
	}

	;

	&.enabled {
		transition: 200ms ease;
	}

	&.button-outlined {
		background-color: transparent;

		@include themify($themes) {
			border-color: themed('button-border-primary');
		}

		&.enabled {
			&:hover {
				cursor: pointer;

				@include themify($themes) {
					color: themed('button-text-colour');
					background-color: themed('button-colour-primary');
				}
			}
		}
	}

	div {
		display: flex;
		align-items: center;
	}

	svg {
		margin-right: 0.25em;
	}

	&.mobile {
		font-size: 12px;
		padding: 0 12px;
		height: 39px;
	}

	&.desktop {
		font-size: 12px;
		min-width: 150px;
		padding: 5px 14px;
	}

	&.disabled {
		opacity: 0.5;
		outline: none;
		cursor: auto;
		pointer-events: none;
	}

	&.button-loading {
		opacity: 1 !important;
	}

	&.button-generating {
		pointer-events: none;

		.loading-spinner {
			transform: scale(1) !important;
			width: 24px;
			height: 16px;

			svg path {
				@include themify($themes) {
					fill: themed('button-text-colour-secondary');
				}
			}
		}
	}

	&.button-full-width {
		width: 100% !important;
	}

	&.button-size-small {
		height: 35px;
		padding: 10px 18px;
		min-width: 130px !important;
	}

	.button-text-fadein {
		animation: fadeIn 200ms ease-in;
	}

	&.button-size-compact {
		padding: 4px 18px;
		font-size: 15px;
		height: auto;
		width: auto;
		min-width: auto !important;
		letter-spacing: 2px;
		font-weight: 600;

		svg {
			width: 20px;
		}

		@media screen and (max-width: $breakpoint-lg) {
			padding: 4px 8px;
			font-size: 12px;
		}
	}

	&.capitalized {
		text-transform: capitalize; 
		font-weight: 500; font-size: 14px; 
		letter-spacing: normal;
	}

	&.normal-text {
		text-transform: none; 
		font-weight: 500; font-size: 14px; 
		letter-spacing: normal;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
		transform: translateY(4px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}