@import "../../core-components/styles/Colours.scss";
@import "../../core-components/styles/Variables.scss";
@import "../../themes/themes.scss";


// @include themify($themes) {
// 	color: themed('secondary-colour');
// }

.bank-overlay-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 16px;
	padding: 40px;

	.header-wrapper {
		display: flex;
		align-items: center;
		gap: 8px;
	}

	.hobson-logo {
		margin-bottom: 40px;
	}

	h3 {
		@include themify($themes) {
			color: themed('secondary-colour');
		}
	}

	.loading-spinner {
		transform: none !important;
		
		svg {
			width: 35px;
			height: 35px;

			path {
				@include themify($themes) {
					fill: themed('primary-colour');
				}
			}
		}
	}
}