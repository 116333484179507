@import "../../themes/themes.scss";

// BG Blocks
.recharts-rectangle {
	transition: 300ms ease;
	@include themify($themes) {
		fill: themed('primary-colour');
	}

	&.inactive-cell {
		@include themify($themes) {
			fill: themed('bar-chart-inactive-bar');
		}
	}
}

.recharts-reference-area {
	@include themify($themes) {
		.recharts-reference-area-rect {
			fill: themed('chart-area-colour-alt');
			fill-opacity: 1;
		}
		&.line-chart-background .recharts-reference-area-rect {
			fill: themed('chart-area-colour');
		}
	}
}

// Background colour
.bar-chart-background {
	.recharts-reference-area-rect {
		@include themify($themes) {
			fill: themed('chart-area-colour');
			fill-opacity: 1;
		}

	}
}

// Text labels
.recharts-text {
	font-size: 12px;
	opacity: 1;

	@include themify($themes) {
		fill: themed('text-colour');
	}
}

.recharts-line {
	&.line-2 {
		.recharts-line-curve {
			@include themify($themes) {
				stroke: themed('contribution-line-colour');
			}
		}
	}
}

.chart-leyenda {
	display: flex;
	flex-direction: row;
	gap: 5px;
	align-items: center;

	&.leyendas {
		gap: 20px;
		margin-bottom: 50px;
	}
}

.reviews-dot {
	width: 8px;
	height: 8px;
	border-radius: 50%;

	&.dot-1 {
		background-color: #4F98FC;
	}

	&.dot-2 {
		background-color: #00BFEB;
	}
}

.recharts-line {
	&.line-2 {
		.recharts-line-curve {
			stroke: #00BFEB !important;
		}
	}
}

// Primary curve line
.recharts-line-curve {
	@include themify($themes) {
		stroke: themed('coordinate-graph-line');
	}
}

// Grid lines
.recharts-cartesian-grid-horizontal {
	line {
		@include themify($themes) {
			stroke: themed('coordinate-graph-grid-line');
			stroke: #D4ECFF; 
		}
	}
}

.recharts-cartesian-axis-line {
	@include themify($themes) {
		stroke: themed('coordinate-graph-grid-line');
	}
}

// Tooltip
.recharts-tooltip-wrapper {
	background-color: #fff;
	padding: 7px;
	border-radius: 8px;
	box-shadow: 0 2px 6px rgba(#000, 0.16);

	.valuations-graph-tooltip-label {
		font-size: 12px;

		@include themify($themes) {
			color: themed('subheader-colour');
		}
	}

	.valuations-graph-tooltip-value, .valuations-graph-tooltip-contribution {
		font-size: 16px;
		padding-left: 12px;
		position: relative;

		&:after {
			content: "";
			width: 7px;
			height: 7px;
			border-radius: 50%;
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	.valuations-graph-tooltip-value {
		&:after {
			@include themify($themes) {
				background-color: themed('coordinate-graph-line');
			}
		}
	}

	.valuations-graph-tooltip-contribution {
		&:after {
			@include themify($themes) {
				background-color: themed('contribution-line-colour');
			}
		}
	}
}

.recharts-tooltip-cursor {
	stroke-width: 2px;
	stroke-dasharray: 3;

	@include themify($themes) {
		stroke: themed('bar-chart-inactive-bar');
	}
}

.recharts-dot {
	&.dot-1 {
		@include themify($themes) {
			fill: themed('bar-chart-inactive-bar');
		}
	}

	&.dot-2 {
		@include themify($themes) {
			fill: themed('bar-chart-inactive-bar');
		}
	}
}