@import "../../../../themes/themes.scss";

.banner-account-filters-container {
	width: 100%;
	position: absolute;
	background: #fff;
	opacity: 0;
	visibility: hidden;
	bottom: 0;
	transform: translateY(50%);
	z-index: 80;
	transition: visibility 300ms, transform 300ms ease-in-out, opacity 300ms ease-in-out;
	box-shadow: 0 0 6px rgba(#000, 0.1);

	&.filters-slide-in {
		visibility: visible;
		opacity: 1;
		transform: translateY(99%);
	}

	@include themify($themes) {
		border-top: 1px solid themed('banner-border');
	}
}

.banner-account-filters {
	width: 100%;
	padding: 12px 18px 7px 18px;
}