@import "./themes/themes.scss";

hr {
	margin: 25px 0;
	opacity: 1;
	@include themify($themes) {
		background-color: themed('hr-colour');
	}
}

.toggle-theme-button {
	position: absolute;
	right: 30px;
	bottom: 30px;
	border: none;
	border-radius: 6px;
	background-color: #505050;
	color: #dadada;
	padding: 11px;
	font-size: 10px;
	font-weight: 700;
	letter-spacing: 1px;
	outline: none !important;
	z-index: 10000;
	transition: 100ms ease;
	box-shadow: 0 0 6px #00000020;

	&:hover {
		opacity: 0.8;
	}

	&:active {
		transform: scale(0.98);
	}
}