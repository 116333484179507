@import "../../themes/themes.scss";
@import "../styles/UtilClasses.scss";

.mobile-account-select-outer {
	height: auto;
	position: relative;
	background-color: #fff;
	z-index: 2;

	@include themify($themes) {
		box-shadow: themed('banner-shadow');
	}
}

.flex-basis-100-percent{
	flex-basis: 100%;
	display: flex;
	flex-direction: row;
}
.mobile-account-select-inner {
	height: 50px;
	transition: 200ms ease;
	overflow: hidden;
	background-color: #fff;
	// &.banner-tall{
	// 	height: 65px;
	// 	@media (max-width: 420px) {
	// 		height: 83px;
	// 	}
	// }
	&.banner-active {
		height: calc(100vh - 127px);
		overflow: hidden;

		// For landscape banner change
		@media screen and (min-width: 500px) and (max-height: 400px){
			height: calc(100vh - 65px);
		}

		.mobile-account-select-banner {
			@include themify($themes) {
				background-color: themed('lightest-colour');
			}
		}

		.mobile-account-select-icon {
			transform: rotate(-180deg);
		}
	}
}

.mobile-account-select-banner {
	padding: 13px 14.5px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #fff;
	position: relative;
	z-index: 1;
	transition: all 300ms ease, box-shadow 0s;
	flex-wrap: wrap;
	.mobile-account-select-icon {
		transition: 400ms ease-in-out;

		@include themify($themes) {
			color: themed('secondary-colour');
		}
	}

	.mobile-account-select-option-wrapper {
		max-width: 90%;
		display: flex;
		align-items: center;
		gap: 5px;


		.mobile-account-select-name {
			// margin-right: 5px;
			font-size: 16px;
			font-weight: 600;

			@include themify($themes) {
				color: themed('secondary-colour');
			}
		}
	}
	
	.token-container {
		margin-right: 0;
	}
}

.mobile-account-banner-options {
	height: calc(100vh - 157px);
	overflow: auto;
	padding-bottom: 50px;

	.account-option {
		padding: 13px 14.5px;
		font-weight: 600;

		.token-container {
			transform: translateY(-2px);
		}

		@include themify($themes) {
			color: themed('secondary-colour');
			border-bottom: 1px solid themed('hr-colour');
		}
	}
}

.fade-in-out {
	animation: fadeInOut 400ms ease;
}

@keyframes fadeInOut {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0;
		transform: translateY(10px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

.mobile-account-safe-custody-tooltip{
	
	.portal-tooltip-popup{
		height: auto;
		min-height: fit-content;
		width: 300px;
		color: $TextColour !important;
		white-space: normal;
	}
}

.mobile-account-safe-custody-icon{
	height: 16px !important;
	width: 16px !important;
}

.mobile-account-safe-custody-container{
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 12px;
	padding-top: 3px
}

.__react_component_tooltip.show{
	max-width: 80vw;
	opacity: 1 !important;
	box-shadow: 0 2px 6px rgba(#000, 0.16);
	
}
.__react_component_tooltip{
	padding: 8px 16px !important
}
.safe-custody-a{
	display: flex;
}