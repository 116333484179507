@import "../../core-components/styles/Colours.scss";
@import "../../themes/themes.scss";

#customer-portal-page {
	width: 100vw;
	height: 100vh;
	display: flex;

	#component-wrapper-container {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		flex: 1;

		&.overflow-y-auto {
			overflow-y: auto;
			// scrollbar-width: none;
		}
	}

	#portal-content {
		flex: 1;
		width: 100%;
		background-color: #f3f3f3;
		overflow-y: scroll;
		padding-bottom: 120px;
	}

	.portal-card {
		.form-error-message {
			color: $Warning;
		}
	}

	&.desktop {
		&.alert-banner-active {
			padding-top: 40px;
		}
		
		#portal-content {
			padding-left: 14.5px;
			padding-top: 14.5px;
			
			.container {
				margin-left: 0px;
			}
		}
	}
}

.faqs-link {
	text-decoration: underline;

	@include themify($themes) {
		color: themed('primary-colour');
	}
}

.header-with-subheader {
	flex-direction: column;
	align-items: flex-start !important;
}