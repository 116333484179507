#portal-account-overview {
	img {
		width: 100%;
		height: auto;
	}

	.card-row {
		display: flex;
		align-items: stretch;
		flex-flow: wrap;

		.portal-card {
			height: 100%;
		}
	}
}