@import "../../../../themes/themes.scss";

// !importants added here to override column widths to prevent button/date range overlap
@media screen and (min-width: 1000px){
.date-range-filter-wrapper {
	max-width: 356px !important;
}

.documents-clear-button {
		max-width: 179px !important;
	}

	.documents-all-button {
			max-width: 200px !important;
	}
}


@media screen and (min-width: 1600px){
.date-range-filter-wrapper {
	max-width: 33.33% !important;
}

.documents-clear-button {
		max-width: 16.66% !important;
	}
}
