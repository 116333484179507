@import "../../themes/themes.scss";

.popup-menu-outer {
	width: auto;
	position: relative;
	cursor: pointer;
}

.popup-element-outer {
	@include themify($themes) {
		background-color: themed('background-colour');
    }
	padding: 4px 0;
	border-radius: 6px;
	box-shadow: 0px 3px 10px #00000016;
	
	.divider {
		margin-bottom: 1rem;
	}
}