
@import "../../themes/themes.scss";
@import "../styles/Variables.scss";

.content-toggler {
	margin: 10px 0;
}

.content-toggler-option {
	font-size: 15px;
	font-weight: 600;
	opacity: 0.5;
	padding: 0 8px 6px 8px;
	position: relative;
	cursor: pointer;
	transition: 200ms ease;
	
	&::after {
		content: "";
		width: 100%;
		height: 2px;
		position: absolute;
		bottom: 0;
		left: 0;
	
		@include themify($themes) {
			background-color: themed('primary-colour');
		}
	}

	&:hover {
		opacity: 1;
	}

	&.active-option {
		opacity: 1;
	}
}

@media screen and (min-width: $breakpoint-lg){
	.content-toggler-option {
		font-size: 18px;
	}
}