@import "../../../themes/themes.scss";
@import "../../styles/Variables.scss";

.horizontal-list-item {
	margin-right: 20px;
	padding-right: 20px;
	margin-bottom: 5px;
	display: flex;
	flex-wrap: wrap;
	font-size: 16px;
	width: 100%;

	@media screen and (min-width: $breakpoint-md) {
		width: auto;
		@include themify($themes) {
			border-right: 1.5px solid themed('divider-colour');
		}
	
		&:last-of-type, &:only-of-type {
			border-right: none;
		}
	}
}

.horizontal-list-item-label {
	margin-right: 6px;
	font-weight: 600;

	@include themify($themes) {
		color: themed('secondary-colour');
	}
}