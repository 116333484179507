@import "../../themes/themes.scss";
@import "../styles/Variables.scss";

.landscape-prompt {
	position: fixed;
	bottom: -20px;
	left: 0;
	padding: 9px 10px;
	width: 100%;
	display: flex;
	visibility: hidden;
	opacity: 0;
	justify-content: center;
	align-items: center;
	height: 45px;
	z-index: 10;
	box-shadow: 0 -1px 4px rgba(#000, 0.1);
	transition: 300ms ease;

	@include themify($themes) {
		background-color: themed('lightest-colour');
		
		p, .screen-rotation-icon {
			color: themed('secondary-colour');
		}
	}

	p {
		margin: 0;
		margin-right: 10px;
		font-size: 13px;
	}

	.screen-rotation-icon {
		font-size: 19px;
	}

	@media screen and (max-width: 560px) {
		transition: 800ms ease;
		visibility: visible;
		bottom: 0;
		opacity: 1;
	}
}

@keyframes fadeAway {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		visibility: hidden;
		bottom: -20px;
	}
}