@import "../../../../themes/themes.scss";

.bank-acc-empty-message {
	font-size: 1rem;
	font-weight: 500;
	margin-bottom: 8px;
}

.bank-acc-add-message {
	cursor: pointer;
	margin-bottom: 0;
	display: inline;
	text-decoration: underline;
	
	@include themify($themes) {
		color: themed('primary-colour');
	}

	
	&:hover {
		opacity: 0.5;
	}
}

.bank-account-field-wrapper {
	.form-label {
		text-transform: uppercase;
		font-weight: bold;
		line-height: 2em;
		letter-spacing: 1px;
		font-size: 14px;
		margin-bottom: 10px;
		
		@include themify($themes) {
			color: themed('input-label-colour');
		}
	}

	.bank-acc-inner-wrapper {
		width: 100%;
		margin-top: 10px;
	}
}

.bank-accounts-col {
	@media screen and (min-width: 1250px) {
	    display: flex;
    	column-gap: 29px;
		flex-wrap: wrap;

		.bank-account-field-wrapper {
			width: 48%;
		}
	}
}

.bank-acc-header {
	justify-content: flex-start !important; 
	gap: 8px;
	align-items: center !important;

	.add-bank-acc-button {
		cursor: pointer;

		&:hover {
			opacity: 0.7;
		}

		transform: translateY(2px);
		@include themify($themes) {
			color: themed('secondary-colour');
		}
	}

	.bank-acc-pending-button {
		cursor: pointer;

		&:hover {
			opacity: 0.7;
		}

		transform: translateY(2px);
		@include themify($themes) {
			color: themed('primary-colour');
		}
	}
}