@import "../../themes/themes.scss";

.mobile-account-banner {
	padding: 13px 14.5px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	background-color: #fff;
	position: relative;
	z-index: 1;

	h4 {
		&:first-of-type {
			margin-right: 5px;
			margin-bottom: 2px;
		}
	}

	@include themify($themes) {
		box-shadow: themed('banner-shadow');

		h4 {
			color: themed('primary-colour');
		}
	}
	
	.token-container {
		font-size: 14px;
		margin-right: 5px;
		
		@include themify($themes) {
			background-color: themed('primary-colour');
		}
	}
}