
@import "../../themes/themes.scss";

.mobile-card-dropdown {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px 6px;
	border-radius: 5px;
	font-size: 15px;
	box-shadow: 0 0 4px rgba(#000, 0.1);
	position: relative;

	.dropdown-option {
		width: 90%;
		position: relative;

		@include themify($themes) {
			color: themed('text-colour');
		}

		&.account-option {
			padding-left: 9px;

			&:before {
				content: "";
				width: 4px;
				height: 80%;
				position: absolute;
				left: 0;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
				border-radius: 100px;
				
				@include themify($themes) {
					background-color: themed('bought-colour');
				}
			}
		}
	}

	.mobile-card-dropdown-options {
		position: absolute;
		visibility: hidden;
		opacity: 0;
		left: 0;
		top: 100%;
		width: 100%;
		background-color: #fff;
		z-index: 3;
		border-radius: 6px;
		overflow-x: hidden;
		box-shadow: 0 1px 4px rgba(#000, 0.1);
		transition: 300ms ease;

		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;

			li {
				padding: 10px;
			}
		}

		&.show-options {
			top: 112%;
			visibility: visible;
			opacity: 1;
		}
	}
}