@import "../../themes/themes.scss";

.search-input__control {
	input {
		transition: none !important;
		border: none !important;
		box-shadow: none !important;
		font-size: 15px;
	}

	@include themify($themes) {
		background-color: themed('input-colour');
		border-color: themed('input-colour');

		&:hover {
			border-color: themed('input-colour');
		}
	}
	
	&.search-input__control--is-focused {
		@include themify($themes) {
			background-color: themed('search-input-colour-focused');
			border-color: themed('search-input-border-colour-focused');
			box-shadow: none;
		}

		.search-input__clear-indicator, .search-input__dropdown-indicator {
			@include themify($themes) {
				color: themed('search-input-indicator-colour');
			}
		}
	}
}

.search-input__option {
	@include themify($themes) {
		color: themed('search-input-option-colour');
		background-color: transparent;

		&.search-input__option--is-focused, &:hover {
			background-color: themed('search-input-option-hover-colour');				
			color: themed('search-input-option-colour');
		}

		&.search-input__option--is-selected {
			color: themed('search-input-colour-focused');				
			background-color: themed('search-input-option-active-colour');				
		}
	}
}

.search-input__value-container {
	padding-left: 37px !important;
}

.search-input-icon {
	position: absolute;
	left: 10px !important;
	bottom: 7px !important;
}

.search-input-wrapper {
	position: relative;
	svg {
		@include themify($themes) {
			fill: themed('filter-input-placeholder');
		}
	}

	&:focus-within {
		svg {
			@include themify($themes) {
				fill: themed('search-input-focused-icon');
			}
		}

		.search-input__clear-indicator {
			@include themify($themes) {
				svg {
					fill: themed('filter-input-placeholder');
					
					&:hover {
						fill: themed('search-input-focused-icon');
					}
				}
			}
		}
	}
}