@import "../../themes/themes.scss";

#time-frame-selector {
	@include themify($themes) {
		button {
			border-bottom: themed('timeframe-border-bottom');
			color: themed('text-colour');
		}

		.selected {
			border-bottom: themed('timeframe-selected-border-bottom');
		}

	}

	button {
		transition: border-bottom 0.25s;
		border-top: 0px;
		border-right: 0px;
		border-left: 0px;
		border-radius: 0px;
		text-transform: none;
		outline: none !important;
		background:white;
		border-top: none !important;
		border-right: none !important;
		border-left: none !important;
		min-width: auto;
		height:  24px;
    }
  
	&.mobile {
		button {
			font-size: 12px;
			padding: 0px 3px 5px 3px;
			width: 32px;
		}
	}

	&.desktop {
		button {
			font-size: 15px;
			padding: 0px 3px 8px 3px;
			width: 39px;
		}
	}
}
