.welcome-page {
    width: 55%;
    padding: 40px 0px;
    margin-left: auto;
    margin-right: auto;

    p {
        text-align: center;
    }

    .portal-toaster {
        .Toastify__toast-container--top-right {
            top: 40px;
            right: 1rem;
        }
    }
}

.welcome-page-header {
    display: flex;
    justify-content: space-between;
    padding: 0px 40px;
    margin-bottom: 70px;
}

.button-with-link {
     a {
        color: #fff !important;
        text-decoration: none;
    }

    &.secondary-button {
        display: flex;
        gap: 10px;
        align-items: center;

        a {
            color: #333B5A !important;
            text-decoration: none;

            .button-size-small {
                min-width: 70px !important;
            }
        }
    }
}