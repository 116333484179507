@import "../../themes/themes.scss";

#alert-banner {
	width: 100vw;
	min-height: 40px;
	padding: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 100;

	@include themify($themes) {
		background-color: themed('secondary-colour');
	}

	.alert-banner-inner {
		display: flex;
		gap: 8px;
		width: auto;
		align-items: center;

		.alert-banner-icon {
			color: #D1E9EF;
			font-size: 16px;
		}

		.alert-banner-message {
			font-size: 14px;
			color: #fff;
		}
	}

}

.mobile {
	#alert-banner {
		position: relative;
	}
}