@import "../../../../core-components/styles/Variables.scss";
@import "../../../../core-components/styles/Mixins.scss";

#tpa-documents-table {
	.grid-table-row {
		align-items: center;
	}
	
	@media screen and (max-width: $breakpoint-xl) {
		.grid-table-row {
			grid-template-columns: 1fr 120px !important;
			padding: 9px 11px;
			grid-gap: 2px;
		}
	
		.column-1 {
			grid-column-start: 2;
			grid-column-end: span 1;
			opacity: 0.7;
			
			.document-tag {
				display: none;
			}
		}
		
		.column-2 {
			@include ellipse;
			grid-column-start: 1;
			grid-column-end: span 1;
			grid-row-start: 2;
			width: auto;
		}
		
		.column-3 {
			display: block;
			font-weight: 600;
			grid-column-start: 1;
			grid-column-end: span 1;
			grid-row-start: 1;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	
		.column-4 {
			display: none;
		}
	}
}