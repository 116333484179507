@import "../../themes/themes.scss";
@import "../styles/Variables.scss";

.tooltip-container {
	position: relative;
	display: block;
	z-index: 9;

	&.active {
		&:hover {
			.portal-tooltip-popup {
				display: block;
			}
		}
	}

	&.left {
		.portal-tooltip-popup {
			left: auto;
			right: 105%;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	&.right {
		.portal-tooltip-popup {
			left: 105%;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	&.bottom {
		.portal-tooltip-popup {
			top: 105%;
		}
	}
	&.medium {
		.portal-tooltip-popup {
			font-size: 13px;
		}
	}
	&.small {
		.portal-tooltip-popup {
			font-size: 12px;
		}
	}
	&.left-aligned {
		.portal-tooltip-popup {
			left: 0;
			transform: translateX(0);
		}
	}
	&.mobile-only {
		@media screen and (max-width: $breakpoint-xl){
			.portal-tooltip-popup {
				display: none !important;
			}
		}
	}
}

.portal-tooltip-popup {
	display: none;
	position: absolute;
	bottom: 105%;
	left: 50%;
	transform: translateX(-50%);
	background-color: #fff;
	box-shadow: 0 2px 6px rgba(#000, 0.16);
	border-radius: 8px;
	padding: 7px;
	font-size: 16px;
	white-space: nowrap;
}