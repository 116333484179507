@import "../../../../core-components/styles/Variables.scss";
@import "../../../../core-components/styles/Mixins.scss";
@import "../../../../themes/themes.scss";

.review-name-token {
	padding: 0 3px;
	border-radius: 6px;
	font-size: 12px;
	font-weight: 500;
	display: flex;
	align-items: center;
	gap: 8px;
	cursor: default;

	@include themify($themes) {
		background-color: themed('input-colour');
		color: themed('silver-grey');
	}

	&.token-required {
		@include themify($themes) {
			background-color: themed('user-token');
			color: themed('primary-colour');
		}
	}

	.signed-icon {
		width: 12px;
		height: 12px;
	}
}