@import "./Colours.scss";
@import "../../themes/themes.scss";
@import "../styles/Variables.scss";

.date-field {
    .invalid-message {
        font-size: 12px !important;
        text-transform: none !important;
        letter-spacing: normal;

        @include themify($themes) {
            color: themed('warning-colour');
        }
    }

    &.invalid {
        .filter-heading {
            display: flex;
            justify-content: space-between;

            @include themify($themes) {
                color: themed('warning-colour');
            }
        }

       .date-field-outer {
            @include themify($themes) {
                border-color: themed('warning-colour');
            }

           &:focus-within {
                svg {
                    @include themify($themes) {
                        color: themed('warning-colour');
                    }
                }
           }
       }
    }
}

.date-field-outer {
    position: relative;
    transition: 200ms ease;
    padding: 6px 10px;

    .calendar-wrapper-showing {
        transform: translateY(104%);
    }

    input {
        background-color: transparent !important;

        &::placeholder {
            opacity: 1 !important;
            @include themify($themes) {
                color: themed('input-placeholder-colour') !important;
            }
        }
    }

    &:focus-within {
        background-color: #fff !important;

        @include themify($themes) {
            box-shadow: themed('input-shadow');
            border-color: themed('primary-colour');

            svg {
                opacity: 1;
                color: themed('primary-colour');
            }
        }

        input {
            box-shadow: none !important;
        }
    }

    @include themify($themes) {
        background-color: themed('input-colour');
        border: 1px solid themed('input-colour');
        color: themed('text-colour');

        svg {
            opacity: 0.6;
        }

        input {
            color: themed('text-colour');
        }
    }

    .react-calendar {
        padding: 0;
        background-color: #fff;
        border-radius: 6px;
        overflow: hidden;
        box-shadow: 0 0 4px rgba(#000, 0.1);

        button {
            background-color: transparent;
            outline: none !important;

            @include themify($themes) {
                color: themed('search-input-option-colour') !important;
            }

            &:hover {
                @include themify($themes) {
                    background-color: rgba(themed('lightest-colour'), 0.5) !important;
                }
            }
        }
    }

    .react-calendar__navigation {
        padding: 10px;
        height: 40px;

        @include themify($themes) {
            button, span {
                font-weight: 600;
                background-color: transparent;
                color: themed('secondary-colour') !important;
            }

            color: themed('secondary-colour') !important;
            background-color: themed("lightest-colour");
        }
    }

    .react-calendar__month-view__weekdays {
        padding: 10px;
        font-size: 11px;
        text-transform: uppercase;
        font-weight: 600;
        padding-bottom: 0;

        @include themify($themes) {
            color: themed('subheader-colour');
        }
    }

    .react-calendar__month-view__weekdays__weekday {
        text-align: center;
        letter-spacing: 1px;
    }

    .react-calendar__month-view__days {
        padding: 10px;

        button {
            background-color: transparent;

            &:hover {
                @include themify($themes) {
                    background-color: rgba(themed('lightest-colour'), 0.5) !important;
                }
            }
        }
    }

    .react-calendar__tile--active {
        @include themify($themes) {
            background-color: themed('lightest-colour');
        }
    }

    .react-calendar__tile.react-calendar__year-view__months__month {
        height: 34px !important;
        font-size: 13px;

        &:hover {
           @include themify($themes) {
                background-color: rgba(themed('lightest-colour'), 0.5);
           }
        }
    }

    .react-calendar__viewContainer {
        padding: 10px;
    }

    @media screen and (max-width: $breakpoint-sm) {
        .calendar-wrapper-showing {
            position: fixed;
            bottom: 0;
            left: 0;
            transform: none;
            width: 100%;
            min-height: 312px;

            .react-calendar {
                width: 100%;
                min-height: 312px;
            }

            .react-calendar__navigation {
                button {
                    width: 40px;
                }
            }
        }
    }
}
