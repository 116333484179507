@import "../../../../core-components/styles/Variables.scss";
@import "../../../../core-components/styles/Mixins.scss";
@import "../../../../themes/themes.scss";

#review-documents-table {
	.grid-table-row {
		align-items: center;
		cursor: pointer;

		&.unread-review {
			font-weight: 600;
			
			@include themify($themes) {
				background: themed('table-row-unread');
			}
		}
	}

	.complete-icon, .unread-icon {
		@include themify($themes) {
			color: themed('primary-colour');
		}
	}

	.complete-icon {
		opacity: 0.5;

		&.review-complete {
			opacity: 1;
		}
	}

	.name-column {
		display: flex;
		align-items: center;
		gap: 8px;

		svg {
			width: 16px;
			height: 16px;
		}
	}

	.column-4 {
		text-align: center;
	}
	
	@media screen and (max-width: $breakpoint-xl) {
		// .grid-table-row {
		// 	grid-template-columns: 1fr 120px !important;
		// 	padding: 9px 11px;
		// 	grid-gap: 2px;
		// }
	
		// .column-1 {
		// 	grid-column-start: 2;
		// 	grid-column-end: span 1;
		// 	opacity: 0.7;
			
		// 	.document-tag {
		// 		display: none;
		// 	}
		// }
		
		// .column-2 {
		// 	@include ellipse;
		// 	grid-column-start: 1;
		// 	grid-column-end: span 1;
		// 	grid-row-start: 2;
		// 	width: auto;
		// }
		
		// .column-3 {
		// 	display: block;
		// 	font-weight: 600;
		// 	grid-column-start: 1;
		// 	grid-column-end: span 1;
		// 	grid-row-start: 1;
		// }
	
		// .column-4 {
		// 	align-items: center;
		// }
	}
}