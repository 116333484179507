@import "../../themes/themes.scss";

.radio-fields {
	display: flex;
	align-items: center;
}

.radio-input-heading {
	font-size: 14px;
	font-weight: 500;
	line-height: 2em;
    letter-spacing: 1px;
	margin: 0px 0px 10px;
}