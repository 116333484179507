@import "../../themes/themes.scss";

table {
	tr {
		&:nth-of-type(4n+2) {
			.expanded-td {
				@include themify($themes) {
					background-color: themed('table-row-highlight-colour') !important;
				}

				td {
					@include themify($themes) {
						background-color: transparent !important;
					}
				}
			}
		}
	}
}
.expanded-row {
	z-index: 2;
	animation: fadeInRow 400ms linear;
	
	td {
		border: none !important;
	}

	&.display-row {
		display: table-row;
	}

	&.row-active {
		-webkit-appearance: none;
		-webkit-box-shadow: 0 1px 4px 0 rgba(#000, 0.08);
		box-shadow: 0 1px 4px 0 rgba(#000, 0.08);


		td {
			@include themify($themes) {
				border-top: themed('table-row-border') !important;
			}

			&:only-child {
				@include themify($themes) {
					border: themed('expanded-table-row-border') !important;
				}
			}
		}
	}
	
	.expanded-row-content {
		width: 85%;
		margin: 0 auto;
		padding: 14px 30px;
		padding-bottom: 40px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-content: center;
		cursor: default;
	
		tr {
			cursor: default !important;
		}

		.expanded-row-table {
			table-layout: fixed;
			width: 100%;

			th {
				&:nth-of-type(3) {
					width: 200px;
				}
			}
		}
	}
}

.expanded-row-left {
	width: 220px;
	margin-right: 20px;

	@include themify($themes) {
		border-right: 1px solid themed('divider-colour');
	}
}

.expanded-row-right {
	flex: 1;
}

.expanded-performance-wrapper {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	text-align: center;
	flex-wrap: wrap;
	display: inline-block;

	.expanded-performance-value {
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 600;
		font-size: 57px;

		svg {
			font-size: 57px;
		}
		
		@include themify($themes) {
			color: themed('bought-colour');
		}
	}

	p {
		font-size: inherit;
		// Margin to give illusion the text is centered
		margin-left: 10px;
	}
}

.expanded-row-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	font-weight: bold;
	margin-bottom: 10px;

	.see-more {
		opacity: 0.5;
		cursor: pointer;

		&:hover {
			opacity: 1;
		}
	}
}

.expanded-row-active {
	z-index: 3;
	@include themify($themes) {
		color: themed('secondary-colour');
		font-weight: 600;

		td {
			border-top: themed('expanded-table-row-border') !important; 

			&:first-of-type {
				border-left: themed('expanded-table-row-border') !important;
			}
			&:last-of-type {
				border-right: themed('expanded-table-row-border') !important;
			}
		}
	}
	-webkit-appearance: none;
	-webkit-box-shadow: 0 -1px 4px 0 rgba(#000, 0.08);
	box-shadow: 0 -1px 4px 0 rgba(#000, 0.08);
}

.expanded-row-table-container {
	overflow-x: auto;
}

@media screen and (max-width: 900px){
	.expanded-row {
		.expanded-row-content {
			width: 95%;
		}
	}
}

@keyframes fadeInRow {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

