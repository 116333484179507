@import "../styles/Colours.scss";
@import "../../themes/themes.scss";

.portal-label {
	width: 100%;

	.label-container {
		display: flex;
		justify-content: space-between;

		.form-label {
			// text-transform: uppercase;
			font-weight: 500;
			line-height: 2em;
			letter-spacing: 1px;

			@include themify($themes) {
				color: themed('input-label-colour');

				&.on-primary {
					color: themed('input-label-colour-on-primary');
				}
			}

			;
		}

		.form-error {
			font-weight: normal;
			line-height: 2em;

			@include themify($themes) {
				color: themed('form-error-message');
			}

			;

			&.down-message {
				.form-error-message {
					font-size: 14px;
				}
			}
		}
	}

	.onbording-address-field {
		&.token-field {
			margin: 0px !important;
			font-size: 15px;

			&.suggestion-text {
				color: #333B5A !important;
			}

			.token-field__control {
				padding: 14px 20px;
				background-color: #ECEEEF;

				&.token-field__control--is-focused {
					margin: 0px;
					background-color: #fff !important;
					border: 1.5px solid #0E8EFD !important;
					height: 46px;
				}

				.token-field__value-container {
					margin: 0px !important;
					padding: 0px !important;
					height: 17px;

					.token-field__single-value {
						color: #333B5A !important;
						margin: 0px;
						padding-left: 5px;
					}

					.token-field__input-container {
						color: #333B5A !important;
						margin: 0px;
						padding: 0px;

						.token-field__input {
							border: none !important;
							box-shadow: none;
							background-color: transparent !important;

							&:focus {
								border: none !important;
								box-shadow: none;
								background-color: transparent !important;
							}
						}

					}
				}
			}

			.token-field__clear-indicator {
				padding: 0px;
				height: 17px;
			}
		}
	}

	.country-field {
		&.token-field {
			margin: 0px !important;
			font-size: 15px;

			&:focus-within {
				.token-field__single-value {
					background-color: transparent !important;
				}
			}

			&.single-value {
				.token-field__control {
					padding: 14px 20px;
					background-color: #ECEEEF;
					margin: 0px;
					height: 46px;

					.token-field__value-container {
						margin-top: -3px !important;
					}
				}

				&:focus-within {
					background-color: transparent !important;
				}
			}

			.token-field__control {
				padding: 10px 20px;
				background-color: #ECEEEF;
				margin: 0px;

				&.token-field__control--is-focused {
					background-color: #fff !important;
					border: 1.5px solid #0E8EFD !important;

					.token-field__input-container {
						.token-field__input {
							border: none !important;
							background-color: #fff;
						}
					}
				}

				.token-field__value-container {
					padding: 0px;

					.token-field__multi-value {
						.token-field__multi-value__label {
							font-size: 15px;
							padding: 0px 3px 0px 6px;
							height: 20px;
							display: flex;
							align-items: center;
						}

						.token-field__multi-value__remove {
							margin: 0px;
							font-size: 15px;
							color: white;
						}
					}

					.token-field__single-value {
						color: #58595B !important;
						margin: 0px 5px 5px;
					}

					.token-field__placeholder {
						color: #58595B !important;
					}

					.token-field__input-container {
						margin: 0px;
						padding: 0px;

						.token-field__input {
							margin-left: 5px;
						}
					}
				}

				.token-field__indicators {

					.token-field__clear-indicator {
						padding: 0px 0px 0px 8px;
						font-weight: 700;
					}
				}
			}
		}
	}


	input {
		font-size: 15px !important;
		line-height: 15px;
		width: 100%;

		&[type="checkbox"] {
			transform: translateY(12px);
			width: auto;
		}

		@include themify($themes) {
			background-color: themed('input-colour');
			// border: themed('input-border');
			border: themed('input-border') !important;

			&:disabled {
				background-color: themed('input-disabled-colour') !important;
				border-color: themed('input-disabled-border-colour') !important;
				color: themed('input-disabled-text-colour') !important;
			}

			&.invalid-input {
				border: 1.5px solid #FFD1DD !important;
				background-color: #FFD1DD !important;
				color: #A0001E;
			}
		}

		;

		&.single-date-picker-field {
			letter-spacing: 1px;
		}

		&:focus {
			background-color: #fff !important;
			border: 1.5px solid #0E8EFD !important;
		}
	}

	&.mobile {
		&.portal-label {
			margin-bottom: 1.3rem;
		}

		.label-container {
			.form-label {
				font-size: 12px;
				margin-bottom: 10px;
			}
		}

		input {
			padding: 15px;
		}
	}

	&.desktop {
		&.portal-label {
			margin-bottom: 20px;
		}

		.label-container {
			.form-label {
				font-size: 14px;
				margin-bottom: 10px;
			}
		}

		input {
			padding: 14px 20px;
		}
	}
}

.search-input__placeholder {
	padding-left: 4px !important;
}

.dropdown-container {
	margin: 0px !important;

	.dropdown-value-wrapper {
		margin: 0px !important;
		background-color: #ECEEEF;
		height: 46px;
		display: flex;
		align-items: center;

		input {
			border: none !important;

			&:focus {
				border: none !important;
				opacity: 1;
				box-shadow: none !important; 
			}

			&::placeholder {
				color: black;
				// opacity: 0.9;
			}
		}

		.placeholder-value {
			opacity: 0.2;
			font-size: 15px;
		}

		.dropdown-value {
			font-size: 15px;
		}

		&:hover {
			box-shadow: none;
		}

		&.dropdown-value__active {
			background-color: #fff !important;
			border: 1.5px solid #0E8EFD;
		}
	}

	.dropdown-selection-list-container {
		box-shadow: -1px 2px 5px rgba(0, 0, 0, 0.1);
		margin-top: 5px;
		padding: 0px !important;
		border-radius: 4px;

		.dropdown-selection-list {
			box-shadow: none;
			background-color: #fff;
			max-height: 260px;
			margin: 0px;

			li {
				&:hover {
					background-color: #f3f6f7;
				}
			}
		}
	}
}

.input-with-dropdown-no-option {
	color: hsl(0, 0%, 60%);
	height: 46px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(13, 142, 253, 0.2);
}

.input-with-dropdown {
	&input {
		border: none;

		&:focus {
			border: none;
		}
	}
}

.icon-inside-input {
	text-align: right;
	margin-right: 10px;
	transform: translateY(-35px);

	i {
		font-size: 20px;
	}
}