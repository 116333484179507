$container-max-size: 1267px;

$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1350px;

$spacer: 1rem !default;

:export {
	xs: $breakpoint-xs;
	sm: $breakpoint-sm;
	md: $breakpoint-md;
	lg: $breakpoint-lg;
	xl: $breakpoint-xl;
	xxl: $breakpoint-xxl;
	cm: $container-max-size;
	spacer: $spacer;
}