@import "../../../../themes/themes.scss";

.as-option {
	padding: 16px 40px;
}

.as-detail-wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	padding-left: 18px;
	font-size: 16px;
	cursor: pointer;
	
	&::after {
		content: "";
		position: absolute;
		width: 6px;
		height: 100%;
		left: 0;
		top: 0;
		opacity: 0.3;
		transition: 200ms ease;
		
		border-radius: 10px;
		@include themify($themes) {
			background-color: themed('primary-colour');
		}
	}

	&:hover {
		&::after {
			opacity: 1;
		}
	}

	&.active-account {
		&::after {
			opacity: 1;
		}
	}
}

.as-account-name {
	font-size: 19px;
	
	.token-container {
		margin-left: 8px;
		transform: translateY(-1px);
		font-size: 13px;
	}
}

.as-valuation {
	font-weight: 600;
	font-size: 19px;
	@include themify($themes) {
		color: themed('primary-colour');
	}
}

