@import "../../../../themes/themes.scss";
@import "../../../../core-components/styles/Mixins.scss";

.notification-doc-wrapper {
	&:first-of-type {
		margin-top: 16px;
	}

	&:last-of-type {
		margin-bottom: 0;
	}

	margin-bottom: 8px;
}

.notification-doc {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 7px;
	padding: 16px 8px;
	cursor: pointer;
	width: 100%;
	transition: 200ms ease;

	.nd-filename {
		font-size: 1rem;
		font-weight: 500;
		width: 90%;
		@include ellipse();
	}

	svg {
		width: 24px;
	}

	@include themify($themes) {
		background-color: themed('lightest-colour');

		&:hover {
			background-color: rgba(themed('primary-colour'), 0.3);
		}

		.nd-filename {
			color: themed('secondary-colour');
		}
		
		svg {
			color: themed('secondary-colour');
		}
	}
}

.notification-doc-outer {
	text-decoration: none;

	&.download-disabled {
		cursor: default;
		pointer-events: none;

		@include themify($themes) {
			&:hover {
				background-color: themed('notification-unread-hover-bg');
			}
		}

		.notification-doc {
			opacity: 0.5;
		}
	}

}