@import "../../../../themes/themes.scss";

@import "../../../../themes/themes.scss";
.notification-item {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    flex-wrap: wrap;
    transition: 200ms ease;

    .notification-delete {
        position: absolute;
        right: 32px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 18px;
        cursor: pointer;
        @include themify($themes) {
            color: themed('light-grey');
        }
        
        &:hover {
            @include themify($themes) {
                color: themed('primary-colour');
            }
        }
    }

    .notification-doc {
        padding: 16px 12px;
        
        @include themify($themes) {
            color: themed('darkest-colour');
            background-color: themed('lightest-colour');
        }
    }

    &.notification-unread {
        .notification-text {
            font-weight: 600;
        }
        @include themify($themes) {
            background-color: themed('notification-unread-bg');
            &:hover {
                background-color: themed('notification-unread-hover-bg');
            }
        }
    }

    &.notification-expanded {
        .notification-text {
            font-weight: 400;
        }
        @include themify($themes) {
            background-color: themed('notification-read');
            &:hover {
                background-color: themed('notification-read');
            }
        }
    }
    
    @include themify($themes) {
        padding: themed('card-list-padding-desktop');
        &:hover {
            background-color: themed('lightest-colour');
            
            .notification-doc {
                background-color: rgba(themed('primary-colour'), 0.2);

                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }

    .notification-li-service-type {
        font-size: 11px;
        height: 16px;
    }
}

.notification-heading {
    line-height: 1.2;
    font-size: 16px;
    // Uncomment styles below to turn on ellipsing.
    // display: -webkit-box;
    // -webkit-line-clamp: 1;
    // -webkit-box-orient: vertical;
    // overflow: hidden;
    // text-overflow: ellipsis;
    margin-bottom: 0;
    @include themify($themes) {
        color: themed('secondary-colour');
    }
}
.notification-text {
    font-weight: 500;
    line-height: 1.2;
    font-size: 16px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 6px;
    font-size: 13px;
    @include themify($themes) {
        color: themed('secondary-colour');
    }

    &.notification-heading {
        font-size: 16px;
        margin-top: 0;
    }
}

.notification-status {
    width: 15px;
    height: 15px;
    min-width: 15px;
    min-height: 15px;
    border-radius: 50%;
    @include themify($themes) {
        background-color: themed('primary-colour');
    }
}

.notification-content-wrapper {
    width: 95%;
    display: flex;
    flex-wrap: wrap;
    gap: 7px;
    position: relative;
}

.notification-type {
    font-weight: 600;
    margin-right: 10px;
}

.notification-item-footer {
    display: flex;
    align-items: center;
}

.notification-date {
    font-size: 12px;
    margin-right: 12px;
    opacity: 0.5;
    @include themify($themes) {
        color: themed('secondary-colour');
    }
}

.notification-expand-button {
    font-size: 12px;
    @include themify($themes) {
        color: themed('primary-colour');
    }
    svg {
        width: 11px;
        height: 11px;
        @include themify($themes) {
            color: themed('primary-colour');
        }
    }
}

.notification-expanded-content {
    width: 95%;
    margin-top: 16px;
    padding-bottom: 10px;
    animation: 300ms ease forwards fadeIn;
}

.notification-expand-content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    .notification-doc {
        width: 49%;
    }
}

.notification-li-header {
    display: flex;
    align-items: center;

    .notification-li-account {
        font-size: 13px;
        margin-left: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        @include themify($themes) {
            color: themed('primary-colour');
        }
    }

    // .notification-li-service-type {
    //     @include themify($themes) {
    //         background-color: themed('primary-colour');
    //     }
    //     margin-right: 5px;
    //     color: #fff;
    //     padding: 0px 5px;
    //     border-radius: 10px;
    //     font-weight: 400;
    //     font-size: 11px;
    //     display: inline-block;
    //     height: 16px;
    // }
}

.notification-content {
    width: 100%;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(-5px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

// .notification-item {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     cursor: pointer;

//     &:hover {
//         .notification-delete {
//             color: rgba(#000, 1);
//         }
//     }

//     @include themify($themes) {
//         background-color: rgba(themed('lightest-colour'), 0.6);
//         padding: themed('card-list-padding-desktop');

//         &:hover {
//             background-color: rgba(themed('lightest-colour'), 1);
//         }
//     }
// }

// .notification-text {
//     font-weight: bold;
// }

// .notification-status {
//     width: 15px;
//     height: 15px;
//     min-width: 15px;
//     min-height: 15px;
//     border-radius: 50%;

//     @include themify($themes) {
//         background-color: themed('primary-colour');
//     }
// }

// .notification-content-wrapper {
//     width: 95%;
// }

// .notification-type {
//     font-weight: 600;
//     margin-right: 10px;
// }

// .notification-date {
//     font-size: 13px;

//     @include themify($themes) {
//         color: themed('primary-colour');
//     }
// }

// .notification-delete {
//     width: 24px;
//     height: 24px;
// 	padding:2px;
//     min-width: 15px;
//     min-height: 15px;
//     border-radius: 50%;
//     color: rgba(#000, 0.3);
// }