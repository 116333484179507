@import "../../../core-components/styles/Colours.scss";
@import "../../../core-components/styles/Variables.scss";
@import "../../../themes/themes.scss";

#portal-logged-out-page {
	h3 {
		@include themify($themes) {
			color: themed('secondary-colour');
		}
		margin-bottom: 16px;
	}
}

.access-message {
	margin-top: 70px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	font-size: 14px;
	
	a {
		text-decoration: underline !important;
		margin-left: 0;
		font-size: 14px !important;

		@include themify($themes) {
			color: themed('primary-colour');
		}

		&:hover {
			opacity: 0.5;
		}
	}
}

@media screen and (max-width: 450px){
	.access-message {
		margin-top: 30px;
		
		p {
			font-size: 10px;
		}

		a {
			font-size: 10px !important;
		}
	}
}