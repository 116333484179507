.hide-scroll-bar {
	scrollbar-width: none; /* Firefox 64 */
	-ms-overflow-style: none; /* Internet Explorer 11 */
	
	&::-webkit-scrollbar { /** WebKit */
		display: none;
	}
}

.ellipse {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	width: 100%;
}