@import "../../../../core-components/styles/Colours.scss";
@import "../../../../themes/themes.scss";

.transaction-list__mobile-transaction {
	@include themify($themes) {
		.transaction-list__left, .transaction-list__right {
			color: themed('text-colour');
		}
	}

	.transaction-list__left {
		width: 50%;
	}

	.transaction-list__right {
		font-size: 15px;
		font-weight: 700;
		text-align: right;
		width: 50%;

		&.positive {
			@include themify($themes) {
				color: themed('bought-colour') !important;
			}
		}

		&.negative {
			@include themify($themes) {
				color: themed('warning-colour') !important;
			}
		}
	}

	&.balance-showing {
		.transaction-list__left {
			width: 40%;
		}

		.total-col {
			width: 20%;
		}

		.balance-col {
			width: 40%;
		}
	}
}
	

.transaction-list__transaction {
	display: flex;
	align-items: center;
	margin-bottom: 7px;
	padding: 19px 8px;
	border-radius: 6px;
}

.transaction-list__mobile-headers {
	display: flex;
	align-items: baseline;
	justify-content: space-between;
}

.transaction-list__mobile-header {
	font-size: 13px;
	margin-bottom: 6px;

	@include themify($themes) {
		color: rgba(themed('text-colour'), 0.5);
	}

	&:nth-of-type(1) {
		width: 40%;
	}

	&:nth-of-type(2) {
		width: 20%;
		text-align: right;
	}

	&:nth-of-type(3) {
		width: 40%;
		text-align: right;
	}
}

.transaction-list__header {
	font-size: 13px;
}

.transaction-list__date {
	font-size: 10px;
	opacity: 0.6;
}