@import "../../core-components/styles/Colours.scss";
@import "../../themes/themes.scss";

#portal-logged-out-page {
	transition: 400ms ease;
	@include themify($themes) {
		background-color: themed("login-background-colour");

		button {
			background-color: themed("button-colour");
			border-color: themed("button-colour");
		}

		input {
			background-color: themed("input-colour");
			border: themed("input-border");
		}

		.form-label {
			color: themed("input-label-colour");
		
			&.on-primary {
				color: themed('input-label-colour-on-primary');
			}
		}

		.form-error-message {
			color: themed("form-error-message");
		}
	}

	.logged-out-wrapper, .top-left {
		padding: 23px;
	}

	.top-left {
		position: absolute;
	}

	.logged-out-wrapper {
		width: 100vw;
		height: 100vh;
		box-sizing: border-box;
		display: flex;
		justify-content: center;
		align-items: center;

		p {
			color: #fff;
		}

		.logo-container {
			text-align: center;
			transition: 200ms ease;
			
			.hobson-logo {
				height: 58px;
				width: auto;
			}
		}

		.logged-out-content {
			@include themify($themes) {
				a {
					color: themed("text-colour");
				}
				p {
					color: themed("text-colour");
				}
				h2 {
					color: themed("secondary-colour");
				}
			}

			.forms {
				button {
					width: 100%;
				}
				a {
					text-decoration: none;
					font-size: 16px;


					@media screen and (max-width: 400px){
						font-size: 14px;
					}
				}
				p {
					margin: 0px;
					text-align: center;
				}
			}
		}
	}

	&.desktop {
		.logged-out-content {
			width: 515px;
		}
	}

	&.mobile {
		.logged-out-content {
			width: 100%;

			h3 {
				width: 100%;
				text-align: center;
				margin-bottom: 14px;

				@include themify($themes) {
					color: themed('primary-colour');
				}
			}
		}

		.logo-top-left {
			width: 100px;
        	position: absolute;
       		left: 23px;
			top: 35px;
			
			svg {
				width: 100%;
			}
		}
	}
}
