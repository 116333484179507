@import "../styles/Colours.scss";

.form-error-message { 
	overflow: hidden;
	font-size: 16px;
	color: $OceanBlue;

	&.message-fade-in {
		animation: messageFadeIn 600ms ease forwards;
	}

	&.message-fade-out {
		animation: messageFadeOut 600ms ease forwards;
	}

	&.warning {
		color: $Warning;
	}

	&.right {
		text-align: right;
	}

	@keyframes messageFadeIn {
		0% {
			opacity: 0;
			transform: translateY(6px);
			height: 0px;
		}
		40% {
			height: 22px;
		}
		100% {
			opacity: 1;
			transform: translateY(0);
		}
	}

	@keyframes messageFadeOut {
		0% {
			height: 22px;
			opacity: 1;
			transform: translateY(0);
		}
		60% {
			height: 22px;
		}
		100% {
			height: 0px;
			opacity: 0;
			transform: translateY(6px);
		}
	}
}
