@import "../../themes/themes.scss";

.text-option-group {
	margin: 10px 0;
	padding: 0;
	list-style-type: none;
}

.text-option {
	display: inline-block;
	margin-right: 24px;
	font-size: 16px;
	opacity: 0.3;
	cursor: pointer;
	transition: 200ms ease;

	@include themify($themes) {
		color: themed('text-colour');
	}

	&:hover {
		opacity: 1;
		@include themify($themes) {
			color: themed('primary-colour');
		}
	}

	&.active-option {
		opacity: 1;
		font-weight: 600;

		@include themify($themes) {
			color: themed('primary-colour');
		}
	}
}