@import "../core-components/styles/Colours.scss";

$light-blue: #f2f9ff;

// Global variables for use within all themes
$transition: 300ms ease;

// Inputs
$input-transition: 200ms ease-in-out;
$input-border-radius: 7px;

// Themes
$themes: (
	hobson: ( // GENERAL
		primary-colour: $GlacierTeal,
		secondary-colour: $OceanBlue,
		lightest-colour: $LightBlue,
		darkest-colour: $OceanBlue,
		background-colour: $White,
		login-background-colour: $White,
		logo-colour: $White,
		mobile-menu-border: 1px solid #b9b9b9,
		silver-grey: $SilverGrey,
		// TYPOGRAPHY
		text-colour: #363636,
		text-colour-on-primary: $White,
		text-colour-warning: $Warning,
		text-colour-warning-on-primary: $White,
		header-colour: $TextColour,
		small-header-colour: $OceanBlue,
		subheader-colour: $SubHeader,
		banner-header-colour: $TextColour,
		background-highlight-text-colour: $White,
		filter-heading-colour: #9B9FA0,
		// INPUT
		input-colour: $InputColour,
		input-border: 1.5px solid $InputColour,
		input-colour-on-primary: $White,
		input-border-on-primary: 1.5px solid $White,
		input-disabled-colour: #F7F7F7,
		input-disabled-border-colour: #F7F7F7,
		input-disabled-text-colour: rgba($TextColour, 0.8),
		input-placeholder-colour: rgba($PlatinumGrey, 0.5),
		input-focused-colour: $InputColour,
		input-focused-border: 1.5px solid $InputColour,
		input-outline: none,
		input-shadow: 0 0 3px rgba(#000, 0.1),
		input-transition: $input-transition,
		input-label-colour: $PlatinumGrey,
		input-label-colour-on-primary: $White,
		input-label-colour-primary-highlight: $White,
		invalid-input-border: #f7a43d,
		filter-input-placeholder: $SubHeader,
		radio-button-border: $GlacierTeal,
		radio-button-active: $GlacierTeal,
		radio-button-inactive: $SilverGrey,
		search-input-colour: $InputColour,
		search-input-colour-focused: #fff,
		search-input-border-colour-focused: $GlacierTeal,
		search-input-option-colour: $OceanBlue,
		search-input-indicator-colour: $OceanBlue,
		search-input-option-hover-colour: $LightBlue,
		search-input-option-active-colour: $GlacierTeal,
		search-input-focused-icon: $GlacierTeal,
		form-error-message: $Warning,
		// BUTTON
		button-text-colour: $White,
		button-text-colour-primary: $White,
		button-text-colour-secondary: $OceanBlue,
		button-text-colour-primary-highlight: $White,
		button-text-colour-secondary-highlight: $White,
		button-border-radius: 30px,
		button-text-transform: uppercase,
		button-text-hover-colour: $White,
		button-colour-primary: $OceanBlue,
		button-colour-hover: $GlacierTeal,
		button-colour-secondary: $LightBlue,
		button-colour-primary-highlight: $OceanBlue,
		button-colour-secondary-highlight: $LightBlue,
		button-colour-drag: $DragArea,
		button-border-primary: 2px solid $OceanBlue,
		button-border-secondary: 2px solid $LightBlue,
		button-border-primary-highlight: 2px solid $OceanBlue,
		button-border-secondary-highlight: 2px solid $LightBlue,
		// LOADING
		loading-overlay-colour: rgba(#333333, 0.3),
		loading-modal-colour: #f7fbff,
		loading-modal-text-colour: #333333,
		loading-modal-shadow: 0 0 12px rgba(#b7ddff, 0.1),
		// SIDE NAV
		side-nav-colour: $White,
		side-nav-icon-colour: $OceanBlue,
		side-nav-border: $BorderGrey,
		// BANNER
		banner-border: $BorderGrey,
		banner-colour: #fff,
		banner-extension-intro: $SilverGrey,
		banner-valuation: $SilverGrey,
		// DIVIDERS
		banner-divider: $SilverGrey,
		divider-colour: $SilverGrey,
		hr-colour: $BorderGrey,
		// TABLES
		table-headers-colour: rgba($TextColour, 0.6),
		table-border-colour: $BorderGrey,
		table-row-border: 1px solid $LightBlue,
		expanded-table-row-border: 2px solid $LightBlue,
		transactions-table-row-border: 1px solid $BorderGrey,
		table-short-name-colour: $GlacierTeal,
		table-short-name-text-colour: $White,
		table-row-highlight-colour: rgba($TableDivider, 0.15),
		table-row-highlight-colour-hover: rgba($LightBlue, 0.8),
		table-header-font: "Lato",
		table-expanded-row-header: $PlatinumGrey,
		table-row-unread: #E2F1F4,
		// CHARTS
		range-bar-colour: $GlacierTeal,
		range-bar-warning-colour: #F7A33D,
		range-bar-container-colour: rgba(#D8D8D8, 0.2),
		// chart-area-colour: #EFFCFF,
		chart-area-colour: #d3f1f7,
		chart-area-colour-alt: #e8fcff,
		coordinate-graph-grid-line: #D4ECFF,
		coordinate-graph-bg: #EFFCFF,
		coordinate-graph-grid-bg: rgb(184, 207, 200),
		coordinate-graph-line: $Bought,
		timeframe-border-bottom: solid 2px rgba($GlacierTeal, 0.2),
		timeframe-selected-border-bottom: solid 2px $GlacierTeal,
		comparison-table-border: 1.25px solid #19424c54,
		comparison-bar-colour-1: $OceanBlue,
		comparison-bar-colour-2: $GlacierTeal,
		comparison-bar-colour-3: #E2F1F4,
		contribution-line-colour: #4F98FC,
		bar-chart-inactive-bar: #90C4D1,
		// MISC
		portal-select-border: 1.5px solid #19424c,
		bought-colour: $Bought,
		warning-colour: $Warning,
		warning-colour-light: rgba($Warning, 0.1),
		notification-hover-colour: rgba($SilverGrey, 0.1),
		card-list-padding-mobile: 12px 18px,
		card-list-padding-desktop: 12px 40px,
		banner-shadow: 0 1px 3px rgba(#000, 0.07),
		document-tag-colour: #49A0B6,
		light-grey: rgba($SilverGrey, 0.8),
		notification-hover: rgba($SilverGrey, 0.1),
		modal-outer-container: rgba(#363636, 0.3),
		user-token: #D1E9EF,
		// bell-icon: $OceanBlue
		//TOASTER
		positive-dark: $positive-dark,
		positive-light: $positive-light,
		warning-dark: $warning-dark,
		warning-light: $warning-light,
		danger-dark: $danger-dark,
		danger-light: $danger-light,
	),
	3skye: ( // GENERAL
		secondary-colour: #1A2038,
		primary-colour: #0D8EFD,
		lightest-colour: #c4e4ff,
		darkest-colour: #1A2038,
		background-colour: #fff,
		white: #fff,
		light-blue: #f2f9ff,
		login-background-colour: #1A2038,
		logo-colour: #0D8EFD,
		mobile-menu-border: 1px solid #b9b9b9,

		// TYPOGRAPHY
		text-colour: #333333,
		text-colour-on-primary: #fff,
		text-colour-warning: #f7a43d,
		text-colour-warning-on-primary: #f7a43d,
		header-colour: #1A2038,
		banner-header-colour: #1A2038,
		background-highlight-text-colour: #fff,

		// INPUT
		input-colour: $InputColour,
		input-border: 1.5px solid $InputColour,
		input-colour-on-primary: $White,
		input-border-on-primary: 1.5px solid $White,
		input-disabled-colour: #F7F7F7,
		input-disabled-border-colour: #F7F7F7,
		input-disabled-text-colour: rgba($TextColour, 0.8),
		input-placeholder-colour: rgba($PlatinumGrey, 0.5),
		input-focused-colour: $InputColour,
		input-focused-border: 1.5px solid $InputColour,
		input-outline: none,
		input-shadow: 0 0 3px rgba(#000, 0.1),
		input-transition: $input-transition,
		input-label-colour: $PlatinumGrey,
		input-label-colour-on-primary: $White,
		invalid-input-border: #e8305a,
		input-label-colour-primary-highlight: $White,
		filter-input-placeholder: $SubHeader,
		radio-button-active: #0D8EFD,
		radio-button-inactive: $SilverGrey,
		search-input-colour: $InputColour,
		search-input-colour-focused: #fff,
		search-input-border-colour-focused: #0D8EFD,
		search-input-option-colour: #0D8EFD,
		search-input-indicator-colour: #0D8EFD,
		search-input-option-hover-colour: $light-blue,
		search-input-option-active-colour: #0D8EFD,
		search-input-focused-icon: #0D8EFD,
		form-error-message: #f7a43d,

		// BUTTON
		button-text-colour: #fff,
		button-text-colour-primary: #1A2038,
		button-text-colour-secondary: #0D8EFD,
		button-text-colour-primary-highlight: #fff,
		button-text-colour-secondary-highlight: #fff,
		button-border-radius: 8px,
		button-text-transform: uppercase,
		button-text-hover-colour: #0D8EFD,
		button-colour-primary: #1A2038,
		button-colour-secondary: #0D8EFD,
		button-colour-primary-highlight: #0D8EFD,
		button-colour-primary-highlight-alt: #fff,
		button-colour-secondary-highlight: #1A2038,
		button-colour-secondary-highlight-alt: #fff,
		button-border-primary: 2px solid #1A2038,
		button-border-secondary: 2px solid #0D8EFD,
		button-border-primary-highlight: 2px solid #1A2038,
		button-border-secondary-highlight: 2px solid #1A2038,

		// LOADING
		loading-overlay-colour: rgba(#333333, 0.3),
		loading-modal-colour: #f7fbff,
		loading-modal-text-colour: #333333,
		loading-modal-shadow: 0 0 12px rgba(#b7ddff, 0.1),

		// SIDE NAV
		side-nav-colour: #1A2038,
		side-nav-icon-colour: #0D8EFD,
		side-nav-border: #314657,

		// BANNER
		banner-border: $BorderGrey,
		banner-colour: #fff,
		banner-extension-intro: $SilverGrey,

		// DIVIDERS
		banner-divider: $SilverGrey,
		divider-colour: $SilverGrey,
		hr-colour: $BorderGrey,

		// TABLES
		table-headers-colour: rgba($TextColour, 0.6),
		table-border-colour: #e0f1ff,
		table-row-border: 1px solid $BorderGrey,
		expanded-table-row-border: 2px solid $light-blue,
		transactions-table-row-border: 1px solid $BorderGrey,
		table-short-name-colour: $GlacierTeal,
		table-short-name-text-colour: $White,
		table-row-highlight-colour: rgba($light-blue, 0.5),
		table-row-highlight-colour-hover: rgba($light-blue, 0.8),
		table-header-font: "Lato",
		table-expanded-row-header: $PlatinumGrey,

		// CHARTS & GRAPHS
		range-bar-colour: #0D8EFD,
		range-bar-warning-colour: #f7a43d,
		coordinate-graph-grid-line: #0D8EFD25,
		coordinate-graph-bg: #0D8EFD,
		coordinate-graph-grid-bg: #0D8EFD,
		coordinate-graph-line: #0D8EFD,
		timeframe-border-bottom: solid 2px #0D8EFD10,
		timeframe-selected-border-bottom: solid 2px #0D8EFD,
		comparison-table-border: 1.25px solid #1A203820,
		comparison-bar-colour-1: #0D8EFD,
		comparison-bar-colour-2: #60559F,
		chart-area-colour: #f2f9ff,
		chart-area-colour-alt: #e0f1ff,

		//TOASTER
		positive-dark: $positive-dark,
		positive-light: $positive-light,
		warning-dark: $warning-dark,
		warning-light: $warning-light,
		danger-dark: $danger-dark,
		danger-light: $danger-light,

		// MISC
		portal-select-border: 1.5px solid #19424c,
		// portal-select-border: 1.5px solid #1A2038,
		bought-colour: #30C9AC,
		bell-icon: #999dad),

	light: ( // GENERAL
		primary-colour: #0E8EFD,
		secondary-colour: #333B5A,
		lightest-colour: #F2F5FC,
		darkest-colour: #333B5A,
		background-colour: $White,
		login-background-colour: $White,
		logo-colour: #0D8EFD,
		mobile-menu-border: 1px solid #b9b9b9,
		// TYPOGRAPHY
		text-colour: #1A2038,
		text-colour-on-primary: $White,
		text-colour-warning: $Warning,
		text-colour-warning-on-primary: $White,
		header-colour: $TextColour,
		small-header-colour: #333B5A,
		subheader-colour: $SubHeader,
		banner-header-colour: $TextColour,
		background-highlight-text-colour: $White,
		filter-heading-colour: #9B9FA0,
		// INPUT
		input-colour: $InputColour,
		input-border: 1.5px solid $InputColour,
		input-colour-on-primary: $White,
		input-border-on-primary: 1.5px solid $White,
		input-disabled-colour: #F7F7F7,
		input-disabled-border-colour: #F7F7F7,
		input-disabled-text-colour: rgba($TextColour, 0.4),
		input-placeholder-colour: rgba($PlatinumGrey, 0.5),
		input-focused-colour: $InputColour,
		input-focused-border: 1.5px solid $InputColour,
		input-outline: none,
		input-shadow: 0 0 3px rgba(#000, 0.1),
		input-transition: $input-transition,
		input-label-colour: $PlatinumGrey,
		input-label-colour-on-primary: $White,
		input-label-colour-primary-highlight: $White,
		filter-input-placeholder: $SubHeader,
		radio-button-border: #4E6465,
		radio-button-active: #0E8EFD,
		radio-button-inactive: $SilverGrey,
		search-input-colour: $InputColour,
		search-input-colour-focused: #fff,
		search-input-border-colour-focused: #94c7ff,
		search-input-option-colour: $OceanBlue,
		search-input-indicator-colour: $OceanBlue,
		search-input-option-hover-colour: $LightBlue,
		search-input-option-active-colour: #94c7ff,
		search-input-focused-icon: #94c7ff,
		form-error-message: #E66780,
		// BUTTON
		button-text-colour: $White,
		button-text-colour-primary: $White,
		button-text-colour-secondary: #333B5A,
		button-text-colour-primary-highlight: $White,
		button-text-colour-secondary-highlight: $White,
		button-border-radius: 30px,
		button-text-transform: uppercase,
		button-text-hover-colour: $White,
		button-colour-primary: #0E8EFD,
		button-colour-hover: #94c7ff,
		button-colour-secondary: #F0F3F8,
		button-colour-primary-highlight: #0E8EFD,
		button-colour-secondary-highlight: #EDF7FF,
		button-border-primary: 2px solid #0E8EFD,
		button-border-secondary: 2px solid #F0F3F8,
		button-border-primary-highlight: 2px solid #0E8EFD,
		button-border-secondary-highlight: 2px solid #EDF7FF,
		// LOADING
		loading-overlay-colour: rgba(#333333, 0.3),
		loading-modal-colour: #f7fbff,
		loading-modal-text-colour: #333333,
		loading-modal-shadow: 0 0 12px rgba(#b7ddff, 0.1),
		// SIDE NAV
		side-nav-colour: $White,
		side-nav-icon-colour: #333B5A,
		side-nav-border: $BorderGrey,
		// BANNER
		banner-border: $BorderGrey,
		banner-colour: #fff,
		banner-extension-intro: $SilverGrey,
		banner-valuation: #58595B80,
		// DIVIDERS
		banner-divider: $SilverGrey,
		divider-colour: $SilverGrey,
		hr-colour: #D5DCDC,
		// TABLES
		table-headers-colour: rgba($TextColour, 0.6),
		table-border-colour: #e0f1ff,
		table-row-border: 1px solid #e0f1ff,
		expanded-table-row-border: 2px solid #e0f1ff,
		transactions-table-row-border: 1px solid $BorderGrey,
		table-short-name-colour: $GlacierTeal,
		table-short-name-text-colour: $White,
		table-row-highlight-colour: rgba(#0D8EFD, 0.05),
		table-row-highlight-colour-hover: rgba($LightBlue, 0.8),
		table-header-font: "Lato",
		table-expanded-row-header: $PlatinumGrey,
		// CHARTS
		range-bar-colour: $GlacierTeal,
		range-bar-warning-colour: #F7A33D,
		range-bar-container-colour: rgba(#D8D8D8, 0.2),
		// chart-area-colour: #EFFCFF,
		chart-area-colour: #f2f9ff,
		chart-area-colour-alt: #e0f1ff,
		coordinate-graph-grid-line: #D4ECFF,
		coordinate-graph-bg: #EFFCFF,
		coordinate-graph-grid-bg: rgb(184, 207, 200),
		coordinate-graph-line: #4F98FC,
		timeframe-border-bottom: solid 2px rgba($GlacierTeal, 0.2),
		timeframe-selected-border-bottom: solid 2px $GlacierTeal,
		comparison-table-border: 1.25px solid #19424c54,
		comparison-bar-colour-1: #0E8EFD,
		comparison-bar-colour-2: rgb(65, 164, 249),
		comparison-bar-colour-3: #EDF7FF,
		contribution-line-colour: #4F98FC,
		bar-chart-inactive-bar: #90C4D1,
		//TOASTER
		positive-dark: $positive-dark,
		positive-light: $positive-light,
		warning-dark: $warning-dark,
		warning-light: $warning-light,
		danger-dark: $danger-dark,
		danger-light: $danger-light,
		// MISC
		portal-select-border: 1.5px solid #19424c,
		bought-colour: $Bought,
		warning-colour: $Warning,
		notification-hover-colour: rgba($SilverGrey, 0.1),
		card-list-padding-mobile: 12px 18px,
		card-list-padding-desktop: 12px 40px,
		banner-shadow: 0 1px 3px rgba(#000, 0.07),
		document-tag-colour: #0e8efd,
		light-grey: rgba($SilverGrey, 0.8),
		notification-hover: rgba($SilverGrey, 0.1),
		modal-outer-container: rgba(#363636, 0.3),
		bell-icon: #999dad),
);

@mixin themify($themes: $themes) {
	@each $theme, $map in $themes {
		.theme-#{$theme} & {
			$theme-map: (
				) !global;

			@each $key, $submap in $map {
				$value: map-get(map-get($themes, $theme), "#{$key}"
				);
			$theme-map: map-merge($theme-map,
					($key: $value)) !global;
		}

		@content;
		$theme-map: null !global;
	}
}
}

@function themed($key) {
	@return map-get($theme-map, $key);
}