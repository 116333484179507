@import "../../themes/themes.scss";

.grid-table-row {
	display: grid;
	border-radius: 6px;
	padding: 17px 10px;
	grid-gap: 5px;
	justify-content: space-between;

	@include themify($themes) {
		background-color: rgba(themed('lightest-colour'), 0.5);
		color: themed('secondary-colour');
	}
}