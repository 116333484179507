@import "../../themes/themes.scss";

.token-container {
    display: inline-block;
    border-radius: 7px;
    padding: 2px 4px;
    font-size: 16px;
    color: #fff;

    @include themify($themes) {
        background-color: themed('primary-colour');
    }

    &.small-token {
        font-size: 12px;
        border-radius: 4px;
    }
}
.token-container-warning{
    background-color: #FFEAA3 !important;
    color: #7C5B00;
    // display: flex;
    // border-radius: 7px;
    // padding: 0px 4px 1px 4px;
    // height: 22px;
    // font-weight: 400;
    // align-items: center;
    // flex-direction: row;

    // &.small-token {
    //     font-size: 12px;
    //     border-radius: 4px;
    //     height: auto;
    //     margin-top: 4px;
    // }
}