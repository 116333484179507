@import "../styles/Colours.scss";
@import "../../themes/themes.scss";
@import "../styles/Variables.scss";

$th-padding: 0.5em;

.portal-asset-table-container {
	// overflow-x: scroll;
	width: 100%;
	
	#portal-asset-table {
		width: 100%;

		.tbody-content,
		.table-header {
			@include themify($themes) {
				border-bottom: solid 1px themed('table-border-colour');
			}
		}

		.tbody-total {
			td {
				font-weight: bold;
			}
		}

		.ticker-col {
			width: 70px;
			display: inline-block;
			margin-right: 7px;
		}

		th {
			color: #00000060;
			font-size: 14px;
			padding: 0px $th-padding 12.5px $th-padding;
			vertical-align: bottom;
		}

		// td,
		// th {
		// 	font-weight: normal;
		// 	text-align: right;
		// }

		// .asset {
		// 	min-width: 300px;
		// 	text-align: left;
		// 	.short-name {
		// 		display: inline-block;
		// 		text-transform: uppercase;
		// 		font-size: 11px;
		// 		font-weight: bold;
		// 		padding: 2px;
		// 		border-radius: 3px;
		// 		margin-top: auto;
		// 		margin-bottom: auto;
		// 		line-height: 11px;

		// 		@include themify($themes) {
		// 			background-color: themed('table-short-name-colour');
		// 			color: themed('table-short-name-text-colour');
		// 		}
		// 	}
		// }

		.asset {
			@media screen and (max-width: $breakpoint-sm) {
				min-width: 80px;
			}
		}

		.current-unit-price {
			max-width: 80px;
		}

		// .current-nzd-value {
		// 	// max-width: 100px;
		// }

		// .percentage-of-portfolio {
		// 	// max-width: 80px;
		// }

		.table-row-highlight {
			@include themify($themes) {
				background-color: themed('table-row-highlight-colour');
			}
		}

		.table-row-clear {
			background-color: white;
		}

		.no-data {
			text-align: center;
			opacity: 0.6;
		}

		&.mobile {
			th {
				font-size: 13px;
				// padding: 8px 0.5em;
			}

			td {
				font-size: 12px;
				padding: 12.5px $th-padding;
			}

			.current-local-value, .current-unit-price {
				min-width: 110px;
			}

			.current-nzd-value, .percentage-of-portfolio {
				min-width: 90px;
			}

		}

		// &.desktop {
		// 	td {
		// 		font-size: 14px;
		// 		padding: 12.5px $th-padding;
		// 	}
		// }
	}
}

.expanded-content {
	background-color: red;
}

.header-right{
	justify-content: right;
	text-align: right;
	// transform: translateX(16px);
}
