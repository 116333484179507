.submit-button {
    font-size: 13px;
    width: 122px
}

.comment-date {
    font-size: 12px;
    opacity: 0.6;
    padding-top: 3px;
}

.template {
    width: 100%;
    padding: 2cm;
    background-color: #fff;
    color: #282F4B;
    font-size: 15px;

    h1 {
        font-size: 38px;
        font-weight: 400;
        color: #1A2038;
        margin: 40px 0px;

        span {
            color: #1A2038;
        }
    }

    h3 {
        font-size: 22px;
        font-weight: 500;
        margin-top: 40px;
        margin-bottom: 20px;
    }

    h4 {
        font-size: 17px;
        font-weight: 600;
        line-height: 24px;
        margin-top: 25px;
        margin-bottom: 15px;
    }

    p {
        margin-bottom: 20px;
        font-size: 15px;
        line-height: 23px;
    }

    ul {
        list-style: initial;
        margin-top: -5px;

        li {
            padding-bottom: 20px;
            line-height: 23px;
        }

        li::marker {
            color: #0E8EFD;
            margin-right: 10px;
            font-size: 20px
          }
    }

    hr {
        background-color: #E5E5E5 !important;
        margin-top: 10px;
        color: #EBEBEB;
    }

    .hobson-logo {
        width: 132px !important;
        // min-height: 39px !important;
    }

    img {
        display: block;
        width: 100%;
        height: auto;
    }

    footer {
        font-size: 12px;
        opacity: 0.6;
        line-height: 17px;
        margin-bottom: 40px;
    }

    table {
        width: 100%;
        border-collapse: collapse;
        font-size: 0.78rem;
        table-layout: fixed;
        margin: 20px 0px;

        tr {
            &:nth-of-type(odd) {
                td {
                    background-color: #fcfeff !important;
                }
            }
        }

        thead {
            background-color: #EDF7FF;
            font-size: 15px;
            font-weight: 500;
            height: 44px;
            color: #19414B;

            th {
                text-align: center;
                padding: 10px;

                &:first-child {
                    text-align: left;
                }

                &:nth-child(2) {
                    border-right: solid 1px #E7EAF2;
                    border-left: solid 1px #E7EAF2;

                    &.no-side-borders {
                        border-right: none;
                        border-left: none;
                    }
                }
            }
        }

        tbody {
            background-color: #edf7ff28 !important;
            font-size: 14px;

            tr {
                background-color: #edf7ff28 !important;
            }

            td {
                border-bottom: solid 1px #E7EAF2;
                text-align: center;
                line-height: 17px;
                background-color: #fcfeff !important;

                &:first-child {
                    text-align: left;
                }

                &:nth-child(2) {
                    border-right: solid 1px #E7EAF2;
                    border-left: solid 1px #E7EAF2;

                    &.no-side-borders {
                        border-right: none;
                        border-left: none;
                    }
                }
            }
        }

        td {
            padding: 10px;
        }

        asset-allocation-blob-row td {
            cursor: default !important;
        }
    }

    #common-risks-table {
        margin-bottom: 60px;

        tr {
            border-bottom: solid 1px #E7EAF2;

            &:last-child {
                border-bottom: none;
            }
        }

        th,
        td {
            border: none;
            text-align: left !important;
            padding-left: 10px;

            &:first-child {
                border-right: solid 1px #E7EAF2;
                width: 254px;
            }
        }
    }

    .hobson-signatures {
        display: flex;
        flex-direction: row;
        gap: 100px;
        margin-top: 30px;
        margin-bottom: 40px;

        hr {
            margin-bottom: 3px;
            margin-top: 8px;
        }

        .hobson-names {
            font-weight: 600;
        }
    }

    .reviews-picture {
        margin-bottom: 30px;
    }

    .reviews-template-input {
        width: 916px;
        height: fit-content;
        padding-top: 10px;
        color: #333B5A;
        line-height: 24px;

        p {
            margin-bottom: 0px !important;
        }
    }

    .reviews-template-input::placeholder {
        color: #333B5A;
        opacity: 0.5;
    }

    .hobson-logo-small {
        width: 14px;
        height: 14px;
    }

    .table-leyenda {
        display: flex;
        flex-direction: row;
        gap: 5px;
        align-items: center;

        &.leyendas {
            gap: 20px;
            font-size: 12px;
            margin-bottom: 10px;
        }
    }

    .two-column-list {
        display: grid;
        grid-template-columns: 330px 330px;
    }

    .recommended-tactical-container {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        width: 100%;
        margin-top: -40px;
    }

    .recomended-tactical-leyenda {
        display: flex;
        flex-direction: row;
        width: 200px;
    }

    .tactical-leyenda-extremes {
        font-size: 12px;
        width: 100px;
        transform: translateY(-10px);
    }

    .tactical-squares {
        display: flex;
        flex-direction: row;
        gap: 3px;
        margin: 10px;
    }

    .tactical-square {
        width: 32px;
        height: 32px;
        border-radius: 6px;
        background-color: #0D8EFD22;

        &.selected-square {
            background-color: #0E8EFD;
        }
    }

    .table-cell-textarea {
        font-size: 14px;
        line-height: 17px;
        margin: 0px;
        background-color: #F8FCFD;
        color: black;
        text-align: left;
    }

    .cell-paragraph {
        margin-bottom: 0px !important;
        line-height: 17px !important;
    }

    .editable {
        cursor: pointer;
    }

    .mix-table {
        display: flex;
        flex-direction: row;
    }

    .duration-information-header {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-bottom: 40px;
    }

    .duration-information-bock {
        display: flex;
        flex-direction: column;
        width: fit-content;
        padding-right: 40px;
    }

    .block-info-title {
        font-size: 12px;
        opacity: 0.6;
        padding-bottom: 15px;
    }

    .block-info-value {
        font-size: 18px;
    }

    .current-and-proposed-portfolio-table { 
        
        .asset-group-title-row {
            font-weight: 600;

            .asset-group-title-cell {
                padding-top: 20px;
            }
    
        }

        tr {
            &:nth-of-type(odd) {
                td {
                    background-color: #fff !important;
                }
            }
        }

        td {
            background-color: #fff !important;
            border-right: none !important;
            border-left: none !important;
        };

        th {
            border-right: none !important;
            border-left: none !important;
        }
    }

    .duration-calculation-review-table {
        tr {
            &:last-child {
                background-color: #EDF7FF;

                td {
                    font-weight: 600;
                }
            }
        };

        td,
        th {
            font-size: 10px !important;
            font-weight: 500;
            text-align: right;

            &:nth-child(4),
            &:nth-child(6),
            &:nth-child(8) {
                border-right: solid 1px #E7EAF2;
                border-left: solid 1px #E7EAF2;
            }

            ;

            &:nth-child(9) {
                border-right: solid 1px #E7EAF2;
            }
        }
    }

    .recomendations-input {
        // background-color: #EDEDED;
        padding: 10px 10px 10px 0px;
    }

    .custody-service-fees-table {

        td,
        th {
            &:nth-child(3) {
                border-right: solid 1px #D1E9EF;
            }
        }
    }
}