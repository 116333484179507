@import "../../themes/themes.scss";

// .pre-login-toaster {
//     .Toastify__toast-theme--light {
//         background: #E0FFF6;
//         color: #00805E;
//     }

//     .MuiSvgIcon-root {
//         font-size: 1rem;
//         opacity: 0.6;
//     }
// }

.toast-close-button {
    width: 1rem;
    height: 1rem;
    opacity: 0.5;

    &:hover {
        opacity: 1;
    }
}

/** Used to define container behavior: width, position: fixed etc... **/
.Toastify__toast-container {
    padding: 0;
}

/** Used to define the position of the ToastContainer **/
.Toastify__toast-container--top-left {
    top: 64px;
    left: 1rem;
}

.Toastify__toast-container--top-center {
    top: 64px;
}

.Toastify__toast-container--top-right {
    top: 64px;
    right: 1rem;
}

/** Classes for the displayed toast **/
.Toastify__toast {
    gap: 0.25rem;
    display: flex;
    align-items: center;
    min-height: 64px;
    transition: 150ms ease;

    &:hover {
        transform: scale(1.03);
    }
}

.Toastify__toast-body {
    padding: 0.5rem;
    // font-family: typography.$font-family-primary;
    padding-left: 0;
    color: inherit;
}

// .Toastify__toast-icon {}
.Toastify__toast--default {
    @include themify($themes) {
        color: themed('neutral-dark');
        background-color: themed('White');
    }
}

.Toastify__toast--info {
    @include themify($themes) {
        color: themed('primary-colour');
        background-color: #f8e2cf //$neutral-blue;
    }
}

.Toastify__toast--success {
    @include themify($themes) {
        color: themed('positive-dark');
        background-color: themed('positive-light');
    }
}

.Toastify__toast--warning {
    @include themify($themes) {
        color: themed('warning-dark');
        background-color: themed('warning-light');
    }
}

.prelogin-toast {
    top: -40px;
}

.Toastify__toast--error {
    @include themify($themes) {
        color: themed('danger-dark');
        background-color: themed('danger-light');
    }
}

.Toastify__progress-bar {
    background: #f8e2cf //$neutral-blue;
}

// .Toastify__progress-bar--rtl {}

.Toastify__progress-bar-theme--light {
    @include themify($themes) {
        background: themed('primary-colour');
        // background: $bg-primary;
    }
}

.Toastify__progress-bar--info {
    @include themify($themes) {
        background: themed('primary-colour');
        // background: $bg-primary;
    }
}

.Toastify__progress-bar--success {
    @include themify($themes) {
        background: themed('positive-dark');
    }
}

.Toastify__progress-bar--warning {
    @include themify($themes) {
        background: themed('warning-dark');
    }
}

.Toastify__progress-bar--error {
    @include themify($themes) {
        background: themed('danger-dark');
    }
}

.toast-undo-button {
    font-size: 0.85rem;
    height: 2rem;
    min-width: auto;
}