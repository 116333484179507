.asset-logo-wrapper {
	width: 32px;
	height: 32px;
	border-radius: 4px;
	overflow: hidden;
	position: relative;

	img {
		width: 100%;
		height: 100%;
		transition: 200ms ease;
	}

	&.no-logo {
		display: flex;
		align-items: center;
		justify-content: center;
		color: rgba(#fff, 0.8);
		background-color: #B6BEC0;
		font-weight: 500;
		font-size: 12px;
	}
}