@import "../styles/Colours.scss";
@import "../../themes/themes.scss";

.grid-table {
	display: grid;
	grid-gap: 7px;
}

.grid-table-header {
	font-size: 16px;
	font-weight: 400;

	@include themify($themes) {
		color: themed('table-headers-colour');
	}
}