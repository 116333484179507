@import "../../themes/themes.scss";
@import "../styles/Variables.scss";

.grid-table-headers{
	padding: 0 10px;
	grid-gap: 5px;
	justify-content: space-between;
	display: none;
	
	@media screen and (min-width: $breakpoint-xl) {
		display: grid;
	}
}

.grid-table-header {
	font-size: 16px;
	font-weight: 400;

	@include themify($themes) {
		color: themed('table-headers-colour');
	}
}