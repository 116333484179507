@import "../../../../themes/themes.scss";

.comment-panel {
    flex-direction: column;
    height: calc(103vh - 360px);
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0px 3px 10px #00000016;
    position: sticky;
    top: 0px;
    opacity: 0;
    width: 295px;
    display: none;
    
    .portal-text-area textarea.desktop {
        padding: 8px;
        width: 100%;
    }
    
    &.display-panel {
        opacity: 1;
        display: flex;
    }

	.empty-state-message {
		width: 100%;
		text-align: center;
		padding: 14px 10px;
		opacity: 0.7;
		
		@include themify($themes) {
			h5, p {
				color: themed('subheader-colour');
			}
		}
		
	}

    @include themify($themes) {
        background-color: themed('background-colour');
        color: themed('primary-colour');
    }
    
    .comment-input-section {
        position: sticky;
        top: 0;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        gap: 4px;
        padding: 12px;
        border-bottom: 1px solid;

		.portal-label.desktop.portal-label {
			margin-bottom: 6px;
		}

        @include themify($themes) {
            border-color: themed('hr-colour');
        }
    
        .comment-input {
            flex: 1;
            border-radius: 4px;
            border: none;
        }
    
        .submit-button {
            cursor: pointer;
            font-size: 13px;
        }
    }
    
    .comment-list-section {
        flex: 1;
        overflow-y: scroll;
        scrollbar-width: none; /* hide scrollbar for Firefox */
        -ms-overflow-style: none; /* hide scrollbar for IE and Edge */
        padding-bottom: 50px;
    
        &::-webkit-scrollbar {
            width: 0px; /* hide scrollbar for Chrome, Safari, and Opera */
        }
    
        .comment-list {
            list-style: none;
            padding: 0;
            margin: 0;
        }
    }
}
