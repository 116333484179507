@import "../../../themes/themes.scss";
@import "../../styles/Variables.scss";

.order-row {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding: 10px 14px;
	border-radius: 6px;
	width: 100%;
	margin-bottom: 7px;
	transition: 200ms ease;
	position: relative;

	@include themify($themes) {
		background-color: rgba(themed('lightest-colour'), 0.5);
		color: themed('secondary-colour')
	}

	// Hide fields for mobile when collapsed
	.standing-order-value-group {
		&.account-number, &.type, &.start-date, &.end-date {
			display: none;
		}
		&.next-run{
			order: 1;
		}
		&.amount {
			order: 2;
			margin-right: 20px;
		}
		&.account-number{
			order: 3;
		}
		&.type {
			order: 4;
		}
		&.start-date {
			order: 5;
			display: none !important;
		}
		&.end-date {
			order: 6;
		}
	}

	@media screen and (max-width: $breakpoint-sm){
		&.expanded-order-row {
			.standing-order-value-group {
				width: auto;
				
				&.account-number, &.type, &.start-date, &.end-date {
					display: block;
				}
			}
		}
	}

	.expand-icon {
		position: absolute;
		right: 10px;
		font-size: 19px;
		top: 10px;
		transition: 200ms;
		opacity: 0.5;
		
		@include themify($themes) {
			color: themed('secondary-colour');
		}

		&.expanded {
			opacity: 1;
			transform: rotate(180deg);
		}
	}
}

@media screen and (min-width: $breakpoint-sm){
	.expand-icon {
		display: none !important;
	}

	.order-row {
		.standing-order-value-group {
			&.account-number, &.type, &.start-date, &.end-date {
				display: block;
			}
			&.next-run{
				order: 1;
			}
			&.account-number{
				order: 2;
			}
			&.type {
				order: 3;
			}
			&.start-date {
				order: 4;
			}
			&.end-date {
				order: 5;
			}
			&.amount {
				order: 6;
			}
		}
	}
}

.order-options {
	margin-left: auto;
	cursor: pointer;

	&:hover {
		opacity: 0.5;
	}
}