@import "../../themes/themes.scss";

.card-list-container {
	height: 100%;
	max-height: 300px;
	overflow-y: auto;
}

.card-list {
	list-style-type: none;
	padding: 0;
	padding-bottom: 40px;
	margin: 0;
}

.card-list-item {
	width: 100%;

	@include themify($themes) {
		border-top: 1px solid themed('hr-colour');
	}
}