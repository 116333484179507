@import "./Overrides.scss";
@import "./Inputs.scss";
@import "./Typography.scss";
@import "../../themes/themes.scss";

.ml-auto {
	margin-left: auto;
}

.fade-in {
	animation: fade-in 0.35s ease-out;
}

.fade-up {
	animation: fade-up 700ms ease;
}

.fade-down {
	animation: fade-up 700ms ease;
}

a {
	@include themify($themes) {
		color: themed('primary-colour');
	}
}

table {
	width: 100%;
	td {
		padding: 12.5px 0.5em;
		font-size: 13px;
		@include themify($themes) {
			border-top: themed('table-row-border');
		}
	}

	th {
		padding: 10px 0.5em;
		font-size: 13px;
	}

	tr {
		&:nth-of-type(odd) {
			td {
				@include themify($themes) {
				background-color: themed('table-row-highlight-colour');
				}
			}
		}

		&:last-of-type, &:only-child {
			td {
				@include themify($themes) {
					border-bottom: themed('table-row-border');
				}
			}
		}
	}

	&.expanded-row-table {
		font-size: 13px;

		th {
			padding: 5px 2px;
		}
		
		td {
			padding: 5px 2px;
			background-color: #fff !important;
			@include themify($themes) {
				color: themed('secondary-colour');
			}
		}
	}

	.wrap-cell {
		word-break: break-all;
	}
}

@keyframes fade-in {
	0% {
		transform: translateY(-5%) scale(1.2);
		opacity: 0;
	}

	100% {
		transform: translateY(0%) scale(1);
		opacity: 100%;
	}
}

@keyframes fade-up {
	0% {
		transform: translateY(5px);
		opacity: 0;
	}

	100% {
		transform: translateY(0);
		opacity: 100%;
	}
}

@keyframes fade-down {
	0% {
		transform: translateY(-5px);
		opacity: 0;
	}

	100% {
		transform: translateY(0);
		opacity: 100%;
	}
}