@import "../../../core-components/styles/Colours.scss";
@import "../../../core-components/styles/Variables.scss";
@import "../../../themes/themes.scss";

#login-view-select {
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 32px;
	padding: 32px;
	padding-bottom: 200px;
}

#login-view-message {
	text-align: center;
}

#login-view-buttons {
	display: flex;
	align-items: center;
	gap: 12px;
	flex-wrap: wrap;
	justify-content: center;
}