@import "../../core-components/styles/Colours.scss";
@import "../../themes/themes.scss";

#portal-mobile-menu-links {
	flex: 1;
	display: block;
	height: calc(100vh - 127px);
	overflow-y: auto;
    position: absolute;
	background-color: #F8F8F8;
    z-index: 11;
	justify-self: flex-end;
	
	
	.container {
		margin-left: 0px;
	}

	@include themify($themes) {
		a {
			display: flex;
			align-items: center;
			border-radius: 4px;
			padding: 12px;
			color: themed('secondary-colour');
			margin-bottom: 5px;
			text-decoration: none;

			&:last-of-type {
				margin-bottom: 0;
			}
	
			svg {
				color: themed('secondary-colour');
			}

			&.active-link {
				background-color: themed('lightest-colour');
				
				.menu-item-arrow-icon {
					opacity: 1;
				}
			}
		}

		.menu-item-arrow-icon {
			margin-left: auto;
			opacity: 0.5;
		}

		.sublinks-wrapper {
			.sublinks-header  {
				padding: 12px;
				color: themed('secondary-colour');
				margin-bottom: 5px;
	
				svg {
					color: themed('secondary-colour');
				}

				.menu-item-arrow-icon {
					transition: 200ms ease;

					&.expanded {
						transform: rotate(-180deg);
					}
				}
			}

			.sub-link {
				padding: 8px;
			}

			.collapse, .collapsing {
				padding-left: 45px;
			}
		}


	}
	
	button {
		width: 100%;
	}

	// For landscape banner change
	@media screen and (min-width: 500px) and (max-height: 400px) {
		height: calc(100vh - 65px);
	}
}

.portal-mobile-menu-links--open {
	width: 100%;
	left: 0%;
	opacity: 1;
	padding: 16px 14.5px;
    transition: left 0.4s, opacity 0.4s;
	padding-bottom: 80px;
}

.portal-mobile-menu-links--closed {
    width: 0%;
    left: 100%;
	opacity: 0;
	padding: 0px 0px;
    transition: width 1ms linear 0.4s, padding 1ms linear 0.4s, left 0.4s, opacity 0.4s;
}
