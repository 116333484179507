@import "../../../../themes/themes.scss";

.document-tag {
	display: inline-flex;
	margin: 0;
	margin-left: 8px;
	color: #fff;
	border-radius: 4px;
	padding: 0 3.5px;
	font-size: 13px;
	align-items: center;
	font-weight: 500;

	svg {
		font-size: 13px;
		opacity: 0.6 !important;
		margin-right: 3px;
	}

	@include themify($themes) {
		background-color: themed('document-tag-colour');
	}


	&:first-of-type {
		margin-left: 11px;
	}
}
