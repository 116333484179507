@import "../../../../core-components/styles/Variables.scss";
@import "../../../../core-components/styles/Mixins.scss";
@import "../../../../themes/themes.scss";

#review-page-wrapper {
	position: relative;
	display: flex;
	align-items: flex-start;
	gap: 8px;
	margin-right: 14.5px;

	.divider-hr {
		color: #EBEBEB;
	}

	.portal-card {
		p {
			max-width: none;
		}
	}

	&.view-fullscreen {

		#review-view-card {

			&.review-details-with-divider {
				// width: auto;
				background-color: #fff;
				margin-left: -40px;

				padding-top: 25px;
				padding-left: 70px;
			}

			transition: 300ms ease;
			z-index: 100;
			position: fixed;
			left: 0;
			top: 0;
			width: 100vw;
			height: 100vh;
			padding: 0 !important;
			overflow: auto;

			.card-header-wrapper {
				position: sticky;
				top: 0;
				padding-top: 40px;
				margin-bottom: 24px;
				padding-bottom: 0;
				width: 100%;
				z-index: 2;

				@include themify($themes) {
					background-color: themed('background-colour');
				}
			}

			.review-page-header {
				width: 100%;
				max-width: 1400px;
				margin: 0 auto;
				padding: 0px;
			}

			.card-content-wrapper {
				width: 100%;
				max-width: 1400px;
				margin: 0 auto;
				padding: 27px;
				border: solid 1px #EBEBEB;
				border-radius: 12px;

				.template {
					padding: 20px 0px !important;
				}
			}

			.confirm-banner-outer {
				width: 100vw;
				left: 0;

				.confirm-banner-inner {
					max-width: 100%;
				}
			}

		}

		&.comments-open {
			#review-view-card {
				width: 100%;

				.review-page-header {
					padding-left: 0px;
					margin: 0px;
				}

				.card-content-wrapper {
					padding-left: 80px;
					width: calc(100% - 350px);
					margin-left: 43px;
					padding: 27px;
					border: solid 1px #EBEBEB;
					border-radius: 12px;
				}

				.card-header-wrapper {
					width: calc(100% - 350px);
					margin-left: 43px;
				}
			}

		}

		.comment-panel {
			position: fixed;
			right: 0;
			top: 0;
			z-index: 100;
			height: calc(100vh - 90px);
			border-radius: 0;
		}
	}

	// Comments open
	&.comments-open {
		#review-view-card {
			width: calc(100% - 272px);
		}
	}
}

#review-view-card {
	position: relative;
	width: 1056px;

	.card-header-wrapper {
		border-bottom: 1px solid;
		margin-bottom: 25px;

		@include themify($themes) {
			border-color: themed('hr-colour');
		}
	}
}

#customer-portal-page {
	&.mobile {
		.confirm-banner-outer {
			width: 100vw;
			height: 80px;
		}
	}
}

.confirm-banner-outer {
	width: calc(100vw - 344px);
	position: fixed;
	right: 0;
	bottom: 0;
	background-color: #fff;
	box-shadow: 0 -2px 4px rgba(#000, 0.15);
	display: flex;
	justify-content: flex-start;
	align-items: center;
	z-index: 80;
	height: 88px;

	.confirm-banner-inner {
		width: 100%;
		max-width: 1267px;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 16px;
	}
}


.review-page-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.review-details {
	display: flex;
	align-items: flex-start;
	gap: 16px;

	.detail-label {
		font-weight: 400;
		letter-spacing: 0;
	}

	hr {
		width: 1px;
		height: 52px !important;
		margin: 0;
		color: #EBEBEB;
	}
}

.review-labelled-detail {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	gap: 4px;
	height: 48px;
}

.review-action-icons {
	display: flex;
	align-items: center;
	gap: 16px;

	svg {
		cursor: pointer;

		@include themify($themes) {
			color: themed('primary-colour');
		}
	}
}

.signature-tokens-container {
	display: flex;
	align-items: center;
	gap: 8px;
}

.action-icon-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 100%;
	height: 100%;

	.popup-outer {
		z-index: 1;
	}

	&::before {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		border-radius: 6px;
		left: 0;
		top: 0;
		z-index: 0;

		@include themify($themes) {
			background-color: themed('lightest-colour');
		}

		opacity: 0;
		transition: 200ms ease;
	}

	&.icon-active {
		&::before {
			opacity: 1;
			transform: scale(1.3);
		}
	}
}

.detail-value {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 5px
}