@import "../../../../themes/themes.scss";

.adviser-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include themify($themes) {
        &:hover {
            background-color: themed("lightest-colour");

            .provider-initials-wrapper {
                background-color: #fff;
            }
        }
    }

    .adviser-email {
        margin-right: 5px;
    }

    .provider-image-wrapper {
        min-width: 29px;
        max-width: 29px;
        height: 29px;
        border-radius: 50px;
        overflow: hidden;
        object-fit: cover;
        margin-right: 0.9em;

        .provider-image {
            width: 29px;
            height: 29px;
            object-fit: cover;
        }
    }
}

.provider-initials-wrapper {
    transition: 200ms ease;
    min-width: 29px;
    max-width: 29px;
    height: 29px;
    border-radius: 50px;
    overflow: hidden;
    margin-right: 0.9em;
    padding-left: 1px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include themify($themes) {
        color: themed('primary-colour');
        background-color: themed('lightest-colour');
    }
}

.adviser-info {
    display: flex;
    width: 75%;
	align-items: center;

    .adviser-name {
        font-weight: 600;
        line-height: 1;
    }

    .adviser-image {
        min-width: 27px;
        max-width: 27px;
        height: 27px;
        margin-right: 8px;
        object-fit: cover;
        border-radius: 50%;
        overflow: hidden;
    }

    .advisor-name-title {
        display: flex;
        flex-direction: column;
    }

    .adviser-role {
        padding-top: 1px;
        color: rgba(#000, 0.4);
        font-size: 12px;
        font-weight: 600;
    }

    .ellipse {
        width: auto;
        padding-right: 10px
    }
}


.adviser-list-icons {
    width: 25%;
    min-width: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    svg {
        opacity: 0.5;
        transition: 200ms ease;
        margin-right: 12px;

        &:last-of-type {
            margin-right: 0;
        }

        &:hover {
            opacity: 1;
        }

        @include themify($themes) {
            color: themed("primary-colour");
        }
    }
}

.mobile {
    .adviser-list-item {
        @include themify($themes) {
            padding: themed("card-list-padding-mobile");
        }
    }
}

.desktop {
    .adviser-list-item {
        @include themify($themes) {
            padding: themed("card-list-padding-desktop");
        }
    }
}