@import "../styles/Colours.scss";
@import "../../themes/themes.scss";

.loading-overlay {
	position: fixed;
	z-index: 11;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0px;
	left: 0px;
	width: 100vw;
	height: 100vh;
	animation: fadeIn 400ms forwards;

	@include themify($themes) {
		background-color: rgba(#f5f5f5, 0.9);
	}
}

.loading-container {
	animation: fadeIn 400ms forwards;
	
	@include themify($themes) {
		.threeskye-loading-icon-outer-ring {
			fill: rgba(themed('primary-colour'), 0.3);
		}

		.threeskye-loading-icon-inner-ring {
			fill: themed('primary-colour');
		}
	}

	&.centered {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.loading-text {
	width: 100%;
	text-align: center;
	margin-top: 10px;
	letter-spacing: 1px;
	font-size: 21px;
	font-weight: 600;
	
	@include themify($themes) {
		color: themed('primary-colour');
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}