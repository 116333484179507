@import "../../themes/themes.scss";

.onbording-header {
    color: #0E8EFD !important;

    &.no-margin {
        margin-bottom: 0px !important;
    }
}

.onbording-phone {
    &.phone-number-edit-container {

        .input-validation-prefix {
            align-items: flex-start !important;

            .prefix-input {
                width: 74px !important;
                margin-top: -1.5px;

                &.token-field {

                    &.input-invalid {
                        .token-field__control {
                            background-color: #FFD1DD !important;
                            border: 1.5px solid #FFD1DD !important;
                            border-right: 1.5px solid #e5bbc3 !important;

                            &.token-field__control--is-focused {
                                border: 1.5px solid #FFD1DD !important;
                                background-color: #FFD1DD !important;
                                border-right: 1.5px solid #e5bbc3 !important;
                            }
                        }
                    }

                    .token-field__control {
                        background-color: #ECEEEF;
                        border: 1.5px solid #ECEEEF;
                        height: 46px;
                        min-width: 60px;

                        .token-field__value-container {
                            .token-field__single-value {
                                margin: 0px 0px 0px 4px !important;
                                font-size: 15px;
                            }
                        }

                        .token-field__input-container {
                            .token-field__input {
                                border: none !important;
                                background-color: transparent !important;
                                box-shadow: none !important;
                            }
                        }

                        &.token-field__control--is-focused {
                            background-color: #fff !important;
                            border: 1.5px solid #0E8EFD !important;

                            .token-field__input-container {
                                .token-field__input {
                                    border: none !important;
                                }
                            }
                        }

                        &.token-field__control--is-disabled {
                            background-color: #F7F7F7 !important;
                            border: 1.5px solid #F7F7F7 !important;

                            .token-field__single-value {
                                margin: 0px 0px 0px 4px !important;
                                font-size: 15px;

                                .prefix-value {
                                    @include themify($themes) {
                                        color: themed('input-disabled-text-colour') !important;
                                    }
                                }
                            }


                        }

                        .token-field__value-container {
                            .token-field__placeholder {
                                color: #1A2038 !important;
                            }
                        }
                    }
                }
            }

            .input-validation-text,
            .extension-input {
                background-color: #ECEEEF;
                border-top-left-radius: 0 !important;
                border-bottom-left-radius: 0 !important;
                border: 1.5px solid #ECEEEF;
                border-left: 2px solid #b6bec06e !important;
                height: 46px !important;

                &.input-validation-text-invalid {
                    box-shadow: none;
                    border: 1.5px solid #FFD1DD !important;
                    background-color: #FFD1DD !important;
                    color: #A0001E;

                    &:focus {
                        box-shadow: none;
                        border: 1.5px solid #FFD1DD !important;
                        background-color: #FFD1DD !important;
                        color: #A0001E;
                        // background-color: #fff !important;
                    }
                }

                &:focus {
                    box-shadow: none;
                    border: 1.5px solid #0E8EFD !important;
                    color: #1A2038 !important;
                }
            }
        }

    }
}

.property-owned-card {
    border: 1.5px solid #D5DCDC;
    padding: 15px 15px 30px;
    margin: 10px 0px;
    border-radius: 4px;

    .property-owned-card-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;

        .delete-icon {
            color: #E66780;
            cursor: pointer;
        }

        .save-icon,
        .edit-icon {
            color: #0E8EFD;
            cursor: pointer;
        }

        .cancel-icon {
            color: #333B5A;
            opacity: 0.6;
            cursor: pointer;
        }
    }

    .property-owned-card-value {
        display: flex;
        align-items: start;
        gap: 2.5px;
        height: 25px;

        p {
            opacity: 0.6;
            font-size: 15px;
            margin: 0px;
        }

        input {
            border: none;
            border-bottom: 1.5px solid #D5DCDC;
            transition: 200ms ease-in-out;
            width: calc(100% - 185px);
            border-radius: 0px;
            font-size: 15px;
            margin-top: -1px;

            &:focus {
                border: none;
                border-bottom: 1.5px solid #0E8EFD;
                background-color: #fff;
                box-shadow: none;
            }
        }
    }
}

.share-sections-label {
    display: grid;
    grid-template-columns: auto 180px 180px 20px;
    gap: 15px;
    margin-top: 10px;

    .share-section-label {
        font-size: 13px;
        color: #ADB1BD;
    }
}

.shares-owned-card {
    display: grid;
    grid-template-columns: auto 180px 180px 20px;
    gap: 1rem;
    align-items: center;
    margin-bottom: 15px;

    .share-section-container {
        input {
            height: 60px;
            background-color: #ECEEEF;
            padding: 10px;
            text-align: right;
            width: 180px;

            &:focus {
                background-color: #fff;
                border: 1.5px solid #0E8EFD;
            }
        }
    }

    .share-section-label {
        font-size: 13px;
        color: #ADB1BD;
        margin-bottom: 7px;
        width: 180px;
    }

    .ticker-info {
        display: flex;
        gap: 5px;
        padding: 10px;
        border: 1.5px solid #D5DCDC;
        border-radius: 4px;
        height: 60px;
        align-items: center;

        .ticker-code {
            font-size: 16px;
        }

        .ticker-name {
            font-size: 13px;
            font-weight: 500;
            color: #ADB1BD;
            padding-left: 5px;
        }
    }

    .delete-icon {
        color: #E66780;
        cursor: pointer;
    }
}

.shares-table-headers {
    display: grid;
    grid-template-columns: auto 180px 180px 20px;
    gap: 1rem;
    font-size: 13px;
    font-weight: 500;
    color: #ADB1BD;
    padding-bottom: 10px;

    .align-right {
        text-align: right;
        padding-right: 5px;
    }

    .align-left {
        text-align: left;
        padding-left: 5px;
    }
}

.business-sections-label {
    display: grid;
    grid-template-columns: auto 160px 70px;
    font-size: 14px;
    padding: 0px 20px 0px 25px;
    margin-top: 25px;

    .right {
        text-align: right;
    }
}

.businesses-owned-row {
    display: grid;
    grid-template-columns: auto 300px 60px;
    border: 1.5px solid #D5DCDC;
    border-radius: 4px;
    padding: 15px 10px;
    font-size: 18px;
    margin-bottom: 15px;

    &.editing {
        grid-template-columns: auto 190px 180px 60px;
    }

    p {
        font-size: 18px;
        font-weight: 500;
        margin: 0px;
    }

    input {
        height: 50px;
        padding: 10px;
        margin: 0px 10px 0px 0px;
        background-color: #ECEEEF;

        &:focus {
            background-color: #fff;
            border: 1.5px solid #0E8EFD;
        }
    }

    .business-values {
        display: flex;
        padding-right: 10px;
        justify-content: right;
        border-right: 1.5px solid #D5DCDC;
    }

    .action-icons {
        display: flex;
        justify-content: right;
        align-items: center;

        i {
            cursor: pointer;
        }

        .save-icon,
        .edit-icon {
            color: #0E8EFD;
        }

        .delete-icon {
            color: #E66780;
        }

        .cancel-icon {
            color: #333B5A;
            opacity: 0.6;
        }
    }

}

.portal-card-title {
    .onbording-address-field {
        &.token-field {
            margin: 0px !important;
            font-size: 15px;

            &.suggestion-text {
                color: #333B5A !important;
            }

            .token-field__control {
                padding: 14px 20px;
                background-color: #ECEEEF;

                &.token-field__control--is-focused {
                    margin: 0px;
                    background-color: #fff !important;
                    border: 1.5px solid #0E8EFD !important;
                    height: 46px;
                }

                .token-field__value-container {
                    margin: 0px !important;
                    padding: 0px !important;
                    height: 17px;

                    .token-field__single-value {
                        color: #333B5A !important;
                        margin: 0px;
                        padding-left: 5px;
                    }

                    .token-field__input-container {
                        color: #333B5A !important;
                        margin: 0px;
                        padding: 0px;

                        .token-field__input {
                            border: none !important;
                            box-shadow: none;
                            background-color: transparent !important;

                            &:focus {
                                border: none !important;
                                box-shadow: none;
                                background-color: transparent !important;
                            }
                        }

                    }
                }
            }

            .token-field__clear-indicator {
                padding: 0px;
                height: 17px;
            }
        }
    }
}

.shares-dropdown {

    .dropdown-selection-list {
        background-color: #fff;
        max-height: 260px;
        margin: 0px;
        box-shadow: -1px 2px 11px rgba(0, 0, 0, 0.1);
        margin-top: 7px;
        // position: absolute;
        // width: 330px;


        li {
            &:hover {
                background-color: #f3f6f7;
            }
        }
    }

}

.asset-allocation-tr {
    display: grid;
    grid-template-columns: 20% 20% 20% 40%;
    border-top: solid 1.5px;
    align-items: center;
    border-top: solid 1.5px #D5DCDC;
    font-size: 15px;
}

.asset-allocation-table-cell {
    padding: 15px 10px 15px 0px;
    background-color: transparent !important;
    border-top: none !important;
    border-bottom: none !important;
    font-size: 15px !important;

    &.justify-self-right {
        justify-self: right;
    }
}

.total-values {
    text-transform: uppercase;
    font-weight: 600;
}

.percentage-bar-container {
    width: 200px;
    height: 7px;
    background-color: #b6ddfe;
    border-radius: 10px;
}

.percentage-bar-progress {
    height: 7px;
    background-color: #0E8EFD;
    border-radius: 10px;
}

.lock-icon {
    @include themify($themes) {
        color: themed('primary-colour') !important;
    }
    opacity: 0.6;
}